import { useContext } from 'react';
import useSessionStorageState from 'use-session-storage-state';

import { PageContext } from '@stories/templates/Context/pageContext';

import type { ProductType as ProductBundleType } from '@stories/organisms/HitListView/ReduceProps';
import type { TProductConfiguration } from '@stories/utlis/context/ProductWithOptionsContext';

type ProductType = {
  name: string;
  priceInclTax: number;
  image: string;
  url: string;
  quantity: number;
  sku: string;
};

type CartType = {
  cartId: string; // "52794191"
  qty: number;
  total: number;
  products: {
    [sku: string]: ProductType;
  };
};

type ProductAddedType = {
  cartId: string; // "52794191"
  message: string; // e.g. "Hagebuttenpulver Bio (500g) wurde in den Warenkorb gelegt."
  name: string;
  sku: string;
  price: string; // localized
  image: string;
  url: string;
  qty: number; // qty added items
  totalQty: number; // total items in cart
  total: string; // total cart value
  notice: boolean; // no idea
};

export type AddToCartResponse = {
  status: string; // TODO 'success' or ???
  data: ProductAddedType;
};

export type AddProductToCartParams = {
  productId: string;
  productConfiguration?: TProductConfiguration | null;
  productType?: ProductBundleType | null;
  quantity?: number;
};

const useCart = () => {
  const [vitafySession, setVitafySession] = useSessionStorageState('vitafy_session', {
    defaultValue: { cart: { qty: 0, total: 0, products: {} } as CartType }
  });

  const { cart } = vitafySession;
  const { setNotificationProductShown } = useContext(PageContext);

  const updateCartProduct = ({ cartId, sku, qty, price, image, name, url }: ProductAddedType) => {
    return {
      ...vitafySession,
      cart: {
        ...cart,
        cartId,
        qty: cart.qty + qty,
        products: {
          ...cart.products,
          [sku]: {
            name,
            priceInclTax: price,
            image,
            url,
            quantity: (cart?.products?.[sku]?.quantity || 0) + qty,
            sku
          }
        }
      } as CartType
    };
  };

  const updateLocalStorage = (productAdded: ProductAddedType) => {
    const updatedVitafySessionObject = updateCartProduct(productAdded);
    setVitafySession(updatedVitafySessionObject);
  };

  const addProduct = async ({
    productId,
    productConfiguration,
    productType,
    quantity = 1
  }: AddProductToCartParams): Promise<AddToCartResponse> => {
    if (!productId || !productType) {
      console.error('useCart.ts: productId and productType are required');
      throw new Error('productId and productType are required');
    }

    const bodyUrlencoded = new URLSearchParams();
    bodyUrlencoded.append('product', productId);
    bodyUrlencoded.append('qty', quantity.toString());
    bodyUrlencoded.append('atc', 'xhr');

    Object.entries(productConfiguration?.select || {}).forEach(([key, value]) =>
      bodyUrlencoded.append(
        `${productType === 'bundle' ? 'bundle_option' : 'super_attribute'}[${key}]`,
        value.toString()
      )
    );

    Object.entries(productConfiguration?.checkbox || {}).forEach(([key, value]) =>
      bodyUrlencoded.append(
        `${productType === 'bundle' ? 'bundle_option' : 'super_attribute'}[${key}][]`,
        value.toString()
      )
    );

    const response = await fetch(`/checkout/cart/add/product/${productId}`, {
      method: 'POST',
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      body: bodyUrlencoded
    });

    if (!response.ok) {
      console.error(response.status, response.statusText);
      throw new Error('productId, productType and productConfiguration are required');
    }

    try {
      const jsonBody = await response.json();
      if (jsonBody.status === 'success') {
        updateLocalStorage(jsonBody.data);
        setNotificationProductShown(true);
      }
      return jsonBody;
    } catch (e) {
      console.error('useCart.ts: couldnt serialize response to json ', e);
      throw new Error('productId, productType and productConfiguration are required');
    }
  };

  return [cart, addProduct] as const;
};

export default useCart;
