/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';

import { zIndexes } from '../utils';

import type { CssProps } from '@stories/@types/custom';

type OverlayProps = {
  visible: boolean;
  clickHandler: () => void;
} & CssProps;

const animationDuration = 250;

const Overlay: React.FC<OverlayProps> = ({ clickHandler, cssProps, visible }) => {
  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [visible]);
  return (
    <div
      css={{
        backgroundColor: 'hsla(0, 0%, 0%, .45)',
        position: 'fixed',
        opacity: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: '-4rem',
        zIndex: zIndexes.darkOverlay,
        transition: `opacity ${animationDuration}ms ease-in, transform 0s ${animationDuration}ms`,
        transform: 'translateX(150vw)',
        ...(visible && {
          backdropFilter: 'blur(6px)',
          height: 'auto',
          opacity: 1,
          transform: 'translateX(0)',
          transition: `opacity ${animationDuration}ms ease-in, transform 0s 0s`
        }),
        ...cssProps
      }}
      onClick={clickHandler}
    />
  );
};

export default Overlay;
export type { OverlayProps };
