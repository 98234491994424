/** @jsxImportSource @emotion/react */
import Button, { type ButtonProps } from '@stories/atoms/Button';
import { mq } from '@stories/theming/settings';

import type { CssProps } from '@stories/@types/custom';

type CallToActionLPProps = {
  ctaPrimary: ButtonProps;
  ctaSecondary?: ButtonProps;
} & CssProps;

const CallToActionLP: React.FC<CallToActionLPProps> = ({ ctaPrimary, ctaSecondary, cssProps }) => {
  return (
    <div
      css={mq({
        columnGap: '1rem',
        display: 'flex',
        flexWrap: ['wrap', null, 'no-wrap'],
        justifyContent: 'center',
        rowGap: '1rem',
        ...cssProps
      })}
    >
      <Button
        {...ctaPrimary}
        cssProps={
          mq({
            flex: ctaSecondary ? ['100% 0 0', null, 'calc(50% - 1rem) 0 0'] : '100% 0 0',
            maxWidth: '360px'
          })[0]
        }
      />
      {ctaSecondary && (
        <Button
          {...ctaSecondary}
          cssProps={
            mq({
              flex: ['100% 0 0', null, '50% 0 0'],
              maxWidth: '360px'
            })[0]
          }
        />
      )}
    </div>
  );
};

export default CallToActionLP;
export type { CallToActionLPProps };
