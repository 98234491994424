import Themes, { type ThemeType } from '@stories/theming';

import type { StoreCodes } from '@gql/hygraph/graphql';

/**
 * Returns theme for given store code
 * @param storeCode - Store code
 * @returns ThemeType for given store code
 */
export function getThemeByStoreCode(storeCode: StoreCodes): ThemeType {
  const theme = Themes[storeCode];
  if (!theme) {
    throw new Error(`Theme for store code ${storeCode} not found`);
  }
  return theme.theme;
}

export function getFontsUrlsByStoreCode(storeCode: StoreCodes): string[] {
  const theme = Themes[storeCode];
  if (!theme) {
    throw new Error(`Theme for store code ${storeCode} not found`);
  }
  return theme.fontUrls;
}
