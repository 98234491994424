/** @jsxImportSource @emotion/react */
import { useContext, useState } from 'react';

import { makeUrlAbsolute, sendGAEvent } from '@common/utlis';
import ChevronBigLeftSvg from '@stories/atoms/Icons/chevron_big_left.svg';
import ChevronRightSvg from '@stories/atoms/Icons/chevron_right.svg';
import { LinkSwitched } from '@stories/atoms/Link';
import { PageContext } from '@stories/templates/Context/pageContext';

import type { CategoryListItemFromDBDto } from '@vitafy/storefront-api-contracts-fetch';

type NavigationProps = {
  categoryTree: CategoryListItemFromDBDto[];
};

const liCssDefault = {
  fontSize: '22px',
  letterSpacing: '-0.01em',
  padding: '20px 0'
} as const;

const ulCssDefault = {
  boxSizing: 'border-box',
  height: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  padding: '2rem 1rem 0 2rem'
} as const;

const categoriesCss = {
  root: {
    ul: () => ulCssDefault,
    li: liCssDefault
  },
  subcategory: {
    ul: () =>
      ({
        ...ulCssDefault,
        display: 'block',
        position: 'absolute',
        backgroundColor: 'white',
        left: 0,
        opacity: 0,
        top: '0',
        width: '100%',
        zIndex: -1
      }) as const,
    li: liCssDefault
  }
};

const activeUlCss = () =>
  ({
    overflow: 'auto',
    opacity: 1,
    zIndex: 1
  }) as const;

const NavigationMultiLayer: React.FC<NavigationProps> = ({ categoryTree }) => {
  const { storeCode } = useContext(PageContext);
  const [activeCategoryPath, setActiveCategoryPath] = useState([1, 1]);

  const createCategories = (
    subcategoryTree: CategoryListItemFromDBDto[],
    path: number[],
    parentName = '',
    parentData: { id?: number; url?: string } = { id: undefined, url: '' }
  ) => {
    const rootCategoryId = 1;
    const categoryId = path.slice(-1)[0] || rootCategoryId;
    const parentPath = path.slice(0, -1);
    const activeCategoryId = activeCategoryPath.slice(-1)[0];
    const activeCategoryParentId = activeCategoryPath.slice(-2)[0];

    const { ul: ulCssFn, li: liCssForCategory } =
      categoryId === rootCategoryId ? categoriesCss.root : categoriesCss.subcategory;

    const isActiveCategory = categoryId === activeCategoryId || categoryId === activeCategoryParentId;

    if (!subcategoryTree?.map) {
      return <div />;
    }

    return (
      <ul
        css={() => ({
          ...ulCssFn(),
          ...(isActiveCategory && activeUlCss())
        })}
        data-category-id={categoryId}
      >
        {categoryId !== rootCategoryId && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              sendGAEvent({
                category: 'Navigation',
                action: `back button click`,
                label: parentName
              });
              setActiveCategoryPath(parentPath);
            }}
            css={(t) => ({
              alignItems: 'center',
              background: 'none',
              border: 'none',
              color: t.color.primary,
              fontFamily: t.font.family.headline,
              fontSize: '20px',
              letterSpacing: '0.02em',
              marginBottom: '2rem',
              padding: 0,
              position: 'relative'
            })}
          >
            <ChevronBigLeftSvg
              css={{
                marginBottom: '.125rem',
                position: 'absolute',
                left: '-29px',
                top: 'calc(50% - 13px)'
              }}
            />
            {parentName}
          </button>
        )}
        {parentData?.id && parentData.url && (
          <li css={liCssForCategory} key={parentData.id} data-category-id={parentData.id}>
            <LinkSwitched
              pathname={`/[store]/category/[categoryId]`}
              query={{ store: storeCode, categoryId: parentData.id }}
              href={makeUrlAbsolute(parentData.url)}
              attrs={{
                'data-category-id': parentData.id,
                onClick: () =>
                  sendGAEvent({
                    category: 'Navigation',
                    action: `level-${path.length - 2}_click`,
                    label: parentName
                  })
              }}
            >
              {`${parentName} Startseite`}
            </LinkSwitched>
          </li>
        )}
        {subcategoryTree.map(({ id, name, children, url, type, path: subcategoryPath }) => (
          <li css={liCssForCategory} key={id} data-category-id={id}>
            {children && children.length ? (
              <>
                <button
                  type="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    sendGAEvent({
                      category: 'Navigation',
                      action: `level-${path.length - 2}_click`,
                      label: `${name}|Expandable`
                    });
                    setActiveCategoryPath(subcategoryPath);
                  }}
                  css={() => ({
                    background: 'none',
                    border: 'none',
                    display: 'flex',
                    alignItems: 'end',
                    gap: '.75rem',
                    padding: 0,
                    fontSize: 'inherit',
                    fontFamily: 'inherit',
                    fontWeight: 'inherit',
                    color: 'inherit'
                  })}
                >
                  {name}
                  <ChevronRightSvg css={(t) => ({ color: t.color.primary, opacity: 0.9 })} />
                </button>
                {createCategories(children, subcategoryPath, name, { url, id })}
              </>
            ) : (
              <LinkSwitched
                pathname={`/[store]/${type}/[categoryId]`}
                query={{ store: storeCode, categoryId: id }}
                href={makeUrlAbsolute(url)}
                attrs={{
                  'data-category-id': parentData.id,
                  onClick: () =>
                    sendGAEvent({
                      category: 'Navigation',
                      action: `level-${path.length - 2}_click`,
                      label: name
                    })
                }}
              >
                {name}
              </LinkSwitched>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <nav data-testid="mobile-navigation" id="navigation-multi-layer">
      {createCategories(categoryTree, [1, 1])}
    </nav>
  );
};

export default NavigationMultiLayer;
export type { NavigationProps };
