/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import { useContext, useEffect, useRef, useState } from 'react';

import { noScrollbarStyle } from '@common/cssUtilities';
import { makeUrlAbsolute, sendGAEvent } from '@common/utlis';
import { LinkSwitched } from '@stories/atoms/Link';
import { PageContext } from '@stories/templates/Context/pageContext';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { TNavigationProps } from '@stories/organisms/Header/Navigation';
import type { CategoryListItemFromDBDto } from '@vitafy/storefront-api-contracts-fetch';

const getStyles = (t) => [
  {
    category: {
      ...t.header.navigation.firstLevelItems,
      lineHeight: '3rem',
      padding: '0 2rem'
    },
    subcategoriesList: {
      backgroundColor: 'white',
      borderBottom: `1px solid ${t.color.grey.g92}`,
      borderTop: `1px solid ${t.color.grey.g92}`,
      padding: '.5rem 0 .5rem 3rem'
    }
  },
  {
    category: {
      ...t.font.small,
      lineHeight: '3rem'
    },
    subcategoriesList: {
      borderLeft: '1px solid #e5e5e5',
      paddingLeft: '2rem'
    }
  },
  {
    category: {
      ...t.font.small,
      lineHeight: '3rem'
    },
    subcategoriesList: {
      paddingLeft: '1rem'
    }
  }
];

const Icon: React.FC<{ open: boolean }> = ({ open }) => (
  <div
    css={(t) => ({
      color: open ? t.color.copy : t.color.primary,
      fontSize: t.font.size.large,
      fontWeight: 'bold',
      position: 'absolute',
      right: '2rem',
      top: '50%',
      transform: 'translateY(-50%)'
    })}
  >
    {open ? '-' : '+'}
  </div>
);

type TNavigationItemProps = CategoryListItemFromDBDto & {
  level: number;
  styles: {
    category: Interpolation<Theme>;
    subcategoriesList: Interpolation<Theme>;
  }[];
};
const NavigationItem: React.FC<TNavigationItemProps> = ({
  children,
  id,
  level,
  name,
  styles: stylesProp,
  type,
  url
}) => {
  const { storeCode, currentCategoryPath, isNavigationShown } = useContext(PageContext);
  const [open, setOpen] = useState(currentCategoryPath?.includes(id) ? true : false);
  const currentCategoryRef = useRef<HTMLDivElement>(null);
  const isCurrentCategory = currentCategoryPath && id === currentCategoryPath[currentCategoryPath.length - 1];

  useEffect(() => {
    currentCategoryPath?.includes(id) ? setOpen(true) : setOpen(false);
    if (currentCategoryRef.current && isNavigationShown) {
      currentCategoryRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isNavigationShown, currentCategoryPath, id]);

  const hasChildren = children?.length > 0;
  const [style, ...styles] = stylesProp;
  const allProductsItem = {
    id: id,
    path: [],
    children: [],
    name: `Startseite ${name}`,
    type: '',
    url: url
  };
  const navigationItemCss = {
    display: 'block',
    position: 'relative',
    textAlign: 'left',
    width: '100%',
    textDecoration: currentCategoryPath?.includes(id) ? 'underline' : 'none'
  } as const;
  const trackClickCallback = () =>
    sendGAEvent({
      category: 'Navigation',
      action: `level-${level}_click`,
      label: `${name}${hasChildren ? '|Expandable' : ''}`
    });
  return (
    <div>
      {hasChildren ? (
        <button
          css={(t) => ({
            ...(navigationItemCss as object),
            color: currentCategoryPath?.includes(id) ? t.color.primary : '-moz-initial'
          })}
          onClick={() => {
            setOpen(!open);
            trackClickCallback();
          }}
        >
          <div css={style?.category}>{name}</div>
          <Icon open={open} />
        </button>
      ) : (
        <LinkSwitched
          attrs={{ 'data-category-id': id, onClick: trackClickCallback }}
          href={makeUrlAbsolute(url)}
          forceAnchorLink={type === 'CMSMediaLink'}
          pathname={`/[store]/${type}/[categoryId]`.replace('//', '/')}
          query={{ store: storeCode, categoryId: id }}
        >
          <div
            ref={id === currentCategoryPath?.[currentCategoryPath.length - 1] ? currentCategoryRef : null}
            css={(t) => ({
              ...(style?.category as object),
              textDecoration: isCurrentCategory ? 'underline' : 'none',
              color: isCurrentCategory ? t.color.primary : '-moz-initial'
            })}
          >
            {name}
          </div>
          {hasChildren && <Icon open={open} />}
        </LinkSwitched>
      )}
      {hasChildren && (
        <div css={{ overflow: 'hidden', ...(!open && { height: 0 }) }}>
          <ul css={{ ...(style?.subcategoriesList as object) }}>
            {[allProductsItem, ...children].map((child) => (
              <NavigationItem key={child.name} {...child} styles={styles} level={level + 1} />
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

const NavigationSingleLayer: React.FC<TNavigationProps> = ({ categoryTree, cssProps }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <nav data-testid="mobile-navigation" id="navigation-single-layer" css={{ ...(cssProps as object) }}>
      <ul
        css={(t) => ({
          backgroundColor: 'hsl(0 0% 96%)',
          color: t.color.grey.g40,
          height: '100vh',
          overflow: 'auto',
          padding: '2rem 0 3rem',
          ...noScrollbarStyle
        })}
      >
        {categoryTree.map((category) => (
          <li key={category.name}>
            <NavigationItem {...category} styles={styles} level={0} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationSingleLayer;
