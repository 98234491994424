/** @jsxImportSource @emotion/react */
import { useContext } from 'react';

import { sendGAEvent } from '@common/utlis';
import { PageContext } from '@stories/templates/Context/pageContext';

type BurgerProps = {
  cssProps?: object;
};

const Burger: React.FC<BurgerProps> = ({ cssProps }) => {
  const { showOverlay, setNavigationShown } = useContext(PageContext);
  return (
    <button
      data-testid="open-mobile-navigation"
      aria-label="Open main navigation"
      type="button"
      onClick={() => {
        sendGAEvent({ category: 'Navigation', action: 'open mobile menu' });
        setNavigationShown(true);
        showOverlay({
          callback: () => {
            setNavigationShown(false);
            sendGAEvent({ category: 'Navigation', action: 'close mobile menu' });
          },
          disableBackButton: false
        });
      }}
      css={() => ({
        background: 'none',
        border: 'none',
        padding: '0 .5rem',
        transform: 'translateX(-.5rem)',
        ...cssProps
      })}
    >
      <div
        css={() => ({
          backgroundColor: 'currentColor',
          height: '2px',
          position: 'relative',
          width: '1.5rem',
          '::after, ::before': {
            backgroundColor: 'currentColor',
            content: '""',
            height: '2px',
            position: 'absolute',
            right: 0,
            width: '100%'
          },
          '::before': {
            top: '-8px'
          },
          '::after': {
            top: '8px'
          }
        })}
      />
    </button>
  );
};

export default Burger;
export type { BurgerProps };
