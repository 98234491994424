/** @jsxImportSource @emotion/react */
import Image from 'next/image';

import { visuallyHidden } from '@common/cssUtilities';
import { insertNewlines } from '@common/utlis';
import { mediaDown, mq } from '@stories/theming/settings';
import { MEDIA_URL } from '@utils/constants';

import type { GetStoreDataQuery } from '@gql/hygraph/graphql';
import type { CssProps } from '@stories/@types/custom';
export type TUSPCard = NonNullable<
  NonNullable<NonNullable<GetStoreDataQuery['store']>['uspCardPack']>['cards'][0]
> & {
  mobileBehavior?: 'compact' | 'default';
} & CssProps;

const primaryStyles = {
  borderRadius: '8px',
  padding: '1.5rem',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1
} as const;

const USPCard: React.FC<TUSPCard> = ({ headline, subheading, icon, variant, cssProps, mobileBehavior }) => {
  const compact = mobileBehavior === 'compact';

  return (
    <div
      css={(t) =>
        mq({
          ...primaryStyles,
          backgroundColor: variant === 'solid' ? t.color.primary : 'none',
          color: variant === 'solid' ? 'white' : 'black',
          border: 'none',
          gap: compact ? ['0.75rem', null, '1.25rem'] : '1.25rem',
          ...(cssProps as object)
        })
      }
    >
      {icon && <Image src={`${MEDIA_URL}/${icon?.url}`} alt={icon?.alt} width={58} height={58} unoptimized />}
      {headline && (
        <h5
          css={(t) =>
            mq({
              fontSize: [t.font.size.small, null, t.font.size.copy],
              fontWeight: 'bold',
              lineHeight: '110%',
              textAlign: 'center',
              textTransform: 'uppercase'
            })
          }
        >
          {insertNewlines(headline)}
        </h5>
      )}
      <div
        css={(t) => ({
          fontSize: t.font.size.footnote,
          textAlign: 'center',
          lineHeight: '130%',
          opacity: '80%',
          ...(mobileBehavior === 'compact' && { [mediaDown('desktop')]: visuallyHidden })
        })}
      >
        {subheading}
      </div>
    </div>
  );
};

export default USPCard;
