import { createContext, useContext, useState } from 'react';

import Notification from '@stories/molecules/Notification';

import type { NotificationType } from '@stories/molecules/Notification';

interface NotificationContextType {
  hideNotification: () => void;
  showNotification: (message: string, type: NotificationType) => void;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notificationShown, setNotificationShown] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState<string>('');
  const [notificationType, setNotificationType] = useState<NotificationType>('info');

  const hideNotification = () => setNotificationShown(false);

  const showNotification = (message: string, type: NotificationType) => {
    setNotificationMessage(message);
    setNotificationType(type);
    setNotificationShown(true);
  };

  return (
    <NotificationContext.Provider
      value={{
        hideNotification,
        showNotification
      }}
    >
      {children}

      <Notification
        shown={notificationShown}
        setShown={setNotificationShown}
        message={notificationMessage}
        type={notificationType}
      />
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
};
