import { useContext } from 'react';

import { PageContext } from '@stories/templates/Context/pageContext';
import { STORE_CODE_LOCALE } from '@utils/constants';

import type { StoreCode } from '@stories/@types/custom';

export type TranslationsDictionaryType = { [key: string]: { [locale: string]: string } };

export const translate: (
  key: string,
  translations: TranslationsDictionaryType,
  options?: { locale?: string; substitutions?: { [key: string]: string } }
) => string = (key, dictionary, options = {}) => {
  const { locale = 'de_DE', substitutions = {} } = options;

  const translationTemplate = dictionary?.[key]?.[locale] || key;

  return Object.entries(substitutions).reduce(
    (acc, [key, value]) => acc.replace(new RegExp('\\$\\{' + key + '\\}', 'g'), value),
    translationTemplate
  );
};

export const getServerSideTranslate = (translations: TranslationsDictionaryType, storeCode: StoreCode) => {
  const locale = STORE_CODE_LOCALE[storeCode] || 'de_DE';
  return (key, substitutions = {}) => translate(key, translations, { substitutions, locale });
};

export default function useTranslate(translations: TranslationsDictionaryType) {
  const { storeCode } = useContext(PageContext);
  const locale = STORE_CODE_LOCALE[storeCode] || 'de_DE';
  return (key, substitutions = {}) => translate(key, translations, { substitutions, locale });
}
