/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react';
import Image from 'next/image';
import { useContext } from 'react';

import { buildMediaUrl } from '@common/utlis';
import Burger from '@stories/atoms/Burger';
import { container } from '@stories/atoms/Grid';
import SearchInput from '@stories/atoms/SearchInput';
import IconDockerHeader from '@stories/molecules/IconDockerHeader';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media } from '@stories/theming/settings';

import ArrowIcon from './Arrow.svg';
import Navigation from './Navigation';

import type { TNavigationProps } from './Navigation';

type HeaderProps = {
  cssProps?: object;
  cinematicMode?: boolean;
  navigation: TNavigationProps;
  centeredSearchBar?: boolean;
  searchInputCallbacks?: {
    onFocus?: React.FocusEventHandler;
    onBlur?: React.EventHandler<React.SyntheticEvent>;
    onChange?: React.EventHandler<React.SyntheticEvent>;
  };
  searchDropdownOpen: boolean;
};

const largeHeaderBreakpoint = 'desktop';

const Header: React.FC<HeaderProps> = ({
  cssProps,
  cinematicMode: cinematicModeProps,
  navigation,
  centeredSearchBar = false,
  searchInputCallbacks = {},
  searchDropdownOpen
}) => {
  const {
    cinematicMode: cinematicModeContext,
    hideOverlay,
    logo: logoFromApi,
    storeCode
  } = useContext(PageContext);
  const cinematicMode = typeof cinematicModeProps === 'boolean' ? cinematicModeProps : cinematicModeContext;
  const theme = useTheme();

  const logo = logoFromApi
    ? {
        aspectRatio: (logoFromApi.width / logoFromApi.height).toFixed(2),
        url: buildMediaUrl(logoFromApi?.src ?? '')
      }
    : {};

  return (
    <header
      css={(t) => ({
        transition: 'background 300ms ease-out',
        ...(cinematicMode ? t.header.cinematic.container : t.header.container),
        ...(cssProps as object)
      })}
    >
      <div
        css={() => ({
          ...container,
          display: 'grid',
          gridTemplateAreas: searchDropdownOpen
            ? '"icon search search search"'
            : '"logo logo empty icons" "burger search search search"',
          gridTemplateColumns: 'auto auto 1fr auto',
          gridColumnGap: '.5rem',
          gridRowGap: '1rem',
          paddingTop: '.75rem',
          paddingBottom: '1rem',
          [media(largeHeaderBreakpoint)]: {
            alignItems: 'center',
            gridRowGap: '1.5rem',
            gridTemplateAreas: '"search logo icons"',
            gridTemplateColumns: centeredSearchBar ? '18rem 1fr 18rem' : '24rem 1fr 24rem',
            justifyContent: 'space-between'
          }
        })}
      >
        <a
          href="/"
          title="Home"
          css={{
            gridArea: 'logo',
            display: searchDropdownOpen ? 'none' : 'block',
            [media('desktop')]: {
              display: 'block',
              textAlign: 'center',
              gridArea: centeredSearchBar ? 'search' : 'logo'
            }
          }}
        >
          <div
            css={(t) => ({
              aspectRatio: logo?.aspectRatio,
              margin: '.25rem 0',
              position: 'relative',
              height: t.header.logo.height,
              ...(cinematicMode && { filter: 'grayscale(1) brightness(100)' }),
              [media(largeHeaderBreakpoint)]: {
                alignSelf: 'center',
                justifySelf: 'center',
                height: 'auto',
                margin: centeredSearchBar ? '.25rem 0' : '.25rem auto',
                width: '210px'
              }
            })}
          >
            <Image src={logo?.url ?? ''} alt={`${storeCode} logo`} fill unoptimized />
          </div>
        </a>
        <IconDockerHeader
          cssProps={{
            alignSelf: 'center',
            gridArea: 'icons',
            display: searchDropdownOpen ? 'none' : 'flex',
            ...(cinematicMode && { filter: 'grayscale(1) brightness(100)' }),
            [media(largeHeaderBreakpoint)]: {
              display: 'flex',
              justifyContent: 'flex-end'
            }
          }}
        />
        <Burger
          cssProps={{
            color: cinematicMode ? 'white' : theme.header.burgerColor,
            gridArea: 'burger',
            display: searchDropdownOpen ? 'none' : 'block',
            [media(largeHeaderBreakpoint)]: { display: 'none' }
          }}
        />
        <button
          type="button"
          css={(t) => ({
            alignItems: 'center',
            color: t.color.highlight,
            cursor: 'pointer',
            display: searchDropdownOpen ? 'flex' : 'none',
            gridArea: 'icon',
            justifyContent: 'center',
            [media('desktop')]: { display: 'none' }
          })}
          onClick={hideOverlay}
        >
          <ArrowIcon />
        </button>
        <SearchInput
          cssProps={{
            gridArea: 'search',
            [media('desktop')]: {
              maxWidth: centeredSearchBar ? 'none' : '22rem',
              gridArea: centeredSearchBar ? 'logo' : 'search'
            }
          }}
          placeholder="Suchen nach Produkten, Rezepten"
          callbacks={searchInputCallbacks}
          cinematicMode={cinematicMode}
        />
      </div>
      <Navigation
        {...navigation}
        cinematicMode={cinematicMode}
        cssProps={{
          display: 'none',
          backgroundColor: cinematicMode ? 'transparent' : 'white',
          [media(largeHeaderBreakpoint)]: { display: 'block' }
        }}
      />
    </header>
  );
};

export default Header;
export type { HeaderProps };
