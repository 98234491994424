'use client';

/** @jsxImportSource @emotion/react */
import Color from 'color';
import { useContext } from 'react';

import { PageContext } from '@stories/templates/Context/pageContext';
import useCart from '@stories/utlis/useCart';
import useWishlist from '@stories/utlis/useWishlist';

import AccountSvg from './icons/account.svg';
import CartSvg from './icons/cart.svg';
import HeartSvg from './icons/heart.svg';

type IconDockerHeaderProps = {
  cssProps?: object;
};

const getBadgeCssFn = (cinematicMode: boolean) => (t) => {
  return {
    position: 'relative',
    '::before': {
      backgroundColor: t.color.highlight,
      boxShadow:
        '0 1px 3px 0 rgb(51 51 51 / 18%), 0 1px 1px 0 rgb(51 51 51 / 15%), 0 2px 1px -1px rgb(51 51 51 / 12%)',
      borderRadius: '100%',
      fontSize: '11px',
      fontWeight: 'normal',
      color: Color(t.color.highlight).isLight() ? 'black' : 'white',
      content: 'attr(data-quantity)',
      display: 'block',
      height: '1rem',
      lineHeight: '1rem',
      position: 'absolute',
      right: '-6px',
      top: '-6px',
      textAlign: 'center',
      width: '1rem',
      ...(cinematicMode && {
        color: 'black',
        fontSize: '15px',
        fontWeight: 'bolder'
      })
    }
  } as const;
};

const CartIcon: React.FC = () => {
  const [cart] = useCart();
  const cartItems = cart.qty;
  const { cinematicMode } = useContext(PageContext);

  return (
    // eslint-disable-next-line @next/next/no-html-link-for-pages
    <a
      href="/checkout/cart"
      data-quantity={cartItems}
      css={() => cartItems > 0 && getBadgeCssFn(cinematicMode)}
    >
      <CartSvg alt="link to the cart page" css={{ display: 'block' }} />
    </a>
  );
};

const IconDockerHeader: React.FC<IconDockerHeaderProps> = ({ cssProps }) => {
  const [wishlistItems] = useWishlist();
  const {
    cinematicMode,
    storeData: { activateWishlist },
    storeId
  } = useContext(PageContext);

  return (
    <div
      css={(t) => ({
        alignItems: 'center',
        color: t.header.color,
        display: 'flex',
        gap: '.75rem',
        opacity: 0.9,
        ...cssProps
      })}
    >
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      <a href="/customer/account/login">
        <AccountSvg alt="link to the login page" css={{ display: 'block' }} />
      </a>
      {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
      {activateWishlist && (
        <a
          href={`/wishlist?id=${storeId}`}
          data-quantity={wishlistItems.length}
          css={() => wishlistItems.length > 0 && getBadgeCssFn(cinematicMode)}
        >
          <HeartSvg alt="link to the wishlist page" css={{ display: 'block' }} />
        </a>
      )}
      <CartIcon />
    </div>
  );
};

export default IconDockerHeader;
export { CartIcon };
export type { IconDockerHeaderProps };
