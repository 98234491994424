import debounce from 'lodash/debounce';
import { useState } from 'react';

import { useResizeListener } from '@common/hooks/useResizeListener';

interface Size {
  width: number | undefined;
  height: number | undefined;
}

const handleResize = debounce((setWindowSize) => {
  setWindowSize({
    width: window?.visualViewport?.width,
    height: window?.visualViewport?.height
  });
}, 100);

const getInitialWindowSize = (): Size =>
  typeof window !== 'undefined'
    ? {
        width: window?.visualViewport?.width,
        height: window?.visualViewport?.height
      }
    : { width: undefined, height: undefined };

export default function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>(getInitialWindowSize);

  useResizeListener(() => handleResize(setWindowSize));

  return windowSize;
}
