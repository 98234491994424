import { breakpoints, media } from '@stories/theming/settings';

import type { viewport } from '@stories/theming/settings';

const container = {
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: 'var(--offset)',
  paddingRight: 'var(--offset)',
  maxWidth: 'var(--container-width)'
} as const;

const row = {
  alignContent: 'flex-start',
  display: 'flex',
  flexWrap: 'wrap',
  marginLeft: 'calc(var(--gutter) / -2)',
  marginRight: 'calc(var(--gutter) / -2)',
  width: 'calc(100% + var(--gutter)%)'
} as const;

const makeColumn = (i: number) =>
  ({
    flex: `0 0 ${(i / 12) * 100}%`,
    maxWidth: `${(i / 12) * 100}%`,
    minHeight: '1px', // Prevent collapsing
    paddingLeft: 'calc(var(--gutter) / 2)',
    paddingRight: 'calc(var(--gutter) / 2)',
    width: '100%'
  }) as const;

type ResponsiveColumnResult = {
  [key: string]: ReturnType<typeof makeColumn>;
} & ReturnType<typeof makeColumn>;

export const makeResponsiveColumn = (span: Array<number | null>): ResponsiveColumnResult =>
  Object.keys(breakpoints).reduce((result, viewport, i) => {
    const column = makeColumn(span[i] as number);

    if (i === 0 && span[i] !== null) {
      Object.assign(result, column);
    } else if (span[i] !== null && span[i] !== undefined) {
      result[media(viewport as viewport)] = column;
    }

    return result;
  }, {} as ResponsiveColumnResult);

export { container, row, makeColumn };
