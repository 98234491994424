import BodylabTheme from './bodylab';
import DefaultTheme, { type DefaultThemeType } from './default';
import VitafyTheme from './vitafy';
import VomachterhofTheme, { fontUrls } from './vomachterhof';

const Themes = {
  default: {
    theme: DefaultTheme,
    fontUrls: []
  },
  bodylab: {
    theme: BodylabTheme,
    fontUrls: []
  },
  bodylab_ch: {
    theme: BodylabTheme,
    fontUrls: []
  },
  vomachterhof: {
    theme: VomachterhofTheme,
    fontUrls: fontUrls
  },
  vomachterhof_ch: {
    theme: VomachterhofTheme,
    fontUrls: fontUrls
  },
  de_de: {
    theme: VitafyTheme,
    fontUrls: []
  }
};

export type ThemeType = DefaultThemeType;
export default Themes;
