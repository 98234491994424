import { createContext, useContext, useState } from 'react';

import { DialogueOverlay } from './DialogueOverlay';

const DialogueOverlayContext = createContext<
  | {
      hideDialogueOverlay: () => void;
      showDialogueOverlay: (
        content: React.ReactNode,
        options?: { callback: () => void; closeOnClick: boolean }
      ) => void;
    }
  | undefined
>(undefined);

export const DialogueOverlayProvider = ({ children }) => {
  const [dialogueOverlayContent, setDialogueOverlayContent] = useState<React.ReactNode>(null);
  const [dialogueOverlayShown, setDialogueOverlayShown] = useState<boolean>(false);

  const hideDialogueOverlay = () => {
    setDialogueOverlayShown(false);
  };

  return (
    <DialogueOverlayContext.Provider
      value={{
        hideDialogueOverlay,
        showDialogueOverlay: (content) => {
          setDialogueOverlayContent(content);
          setDialogueOverlayShown(true);
        }
      }}
    >
      {children}

      <DialogueOverlay open={dialogueOverlayShown} setOpen={hideDialogueOverlay}>
        {dialogueOverlayContent}
      </DialogueOverlay>
    </DialogueOverlayContext.Provider>
  );
};

export const useDialogueOverlayContext = () => {
  const context = useContext(DialogueOverlayContext);
  if (!context) {
    throw new Error('useDialogueOverlayContext must be used within a DialogueOverlayContext');
  }
  return context;
};
