import { useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const listenerCallbacks = new WeakMap<any, (entry: IntersectionObserverEntry) => void>();

export type UseIntersectionOptions = {
  name: string;
  rootMargin?: string;
  threshold?: number;
};

const defaultObserverOptions = {
  rootMargin: '0px',
  threshold: 0.15
};

// intersection observer by threshold
const observers: {
  [key: string]: IntersectionObserver;
} = {};

function handleIntersections(entries: IntersectionObserverEntry[], observer: IntersectionObserver) {
  entries.forEach((entry) => {
    if (listenerCallbacks.has(entry.target)) {
      const cb = listenerCallbacks.get(entry.target)!;

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        entry.target.setAttribute('data-impression', 'true');
        observer.unobserve(entry.target);
        listenerCallbacks.delete(entry.target);
        cb(entry);
      }
    }
  });
}

function getIntersectionObserver({ name = 'defaultObserver', ...options }: UseIntersectionOptions) {
  if (observers[name] === undefined) {
    observers[name] = new IntersectionObserver(handleIntersections, options || defaultObserverOptions);
  }
  return observers[name];
}

export function useIntersection(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elem: React.RefObject<any>,
  callback: (entry: IntersectionObserverEntry) => void,
  observerOptions: UseIntersectionOptions = { name: 'defaultObserver' }
) {
  useEffect(() => {
    const target = elem?.current;
    if (!target) {
      console.error('useIntersection: target is undefined');
      return;
    }
    if (target?.dataset?.impression) {
      return;
    }
    const observer = getIntersectionObserver(observerOptions);
    listenerCallbacks.set(target, callback);
    observer.observe(target);

    return () => {
      listenerCallbacks.delete(target);
      observer.unobserve(target);
    };
  }, [callback, elem, observerOptions]);
}

export default useIntersection;
