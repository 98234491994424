/** @jsxImportSource @emotion/react */

import useTranslate from '@common/hooks/useTranslate';
import { container } from '@stories/atoms/Grid';
import { usePageContext } from '@stories/templates/Context/pageContext';
import { media, mq } from '@stories/theming/settings';
import { useCustomPriceContext } from '@stories/utlis/context/CustomPriceContext';

import type { VoucherDto } from '@vitafy/storefront-api-contracts-fetch';

export type VoucherBarProps = VoucherDto;

const translations = {
  'your voucher code': {
    de_DE: 'Dein Gutscheincode'
  },
  'valid until': {
    de_DE: 'gültig bis:'
  }
};

export const VoucherBar: React.FC = () => {
  const { customPrice, productVoucher, voucher } = useCustomPriceContext();
  const { formatPrice } = usePageContext();
  const translate = useTranslate(translations);

  if (!customPrice && !productVoucher && !voucher) {
    return null;
  }

  const formatEndDate = (date: string | Date) => {
    // Format the end date
    const endDateObject = date instanceof Date ? date : new Date(date);
    const endDateFormatted = endDateObject.toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });

    return endDateFormatted;
  };

  const data = voucher
    ? {
        discount: voucher.discount ? `-${voucher.discount}%` : undefined,
        code: voucher.code,
        description: [`${translate('valid until')} ${formatEndDate(voucher.endDate)}`, ...voucher.description]
      }
    : customPrice
      ? {
          discount: `${formatPrice(customPrice.price)}`,
          code: customPrice.couponCode,
          description: [
            `Nur gültig für dieses Produkt`,
            'Nicht mit anderen Aktionen kombinierbar',
            'Zeitbegrenzt'
          ]
        }
      : {};

  return (
    <section
      id="vitafy-voucher-banner"
      css={(t) => ({
        backgroundColor: t.color.primaryBackground,
        color: 'white'
      })}
    >
      <div
        css={{
          ...container,
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-around',
          padding: '1rem'
        }}
      >
        {data.discount && (
          <div css={mq({ fontSize: ['30px', '35px', '40px', '50px'], fontWeight: 'bold' })}>
            {data.discount}
          </div>
        )}
        <h2>
          <span
            css={(t) => ({
              display: 'block',
              lineHeight: '110%',
              textAlign: 'center',
              [media('desktop')]: {
                backgroundColor: 'white',
                color: t.color.primaryBackground,
                padding: '.75rem 3rem'
              }
            })}
          >
            <span
              css={(t) => ({
                display: 'block',
                fontSize: t.font.size.footnote,
                [media('desktop')]: { color: t.color.copy }
              })}
            >
              {translate('your voucher code')}
            </span>
            <span css={(t) => ({ fontSize: t.font.size.large, fontWeight: 700 })}>{data.code}</span>
          </span>
        </h2>
        <div
          css={(t) =>
            mq({ display: ['none', 'none', 'block'], fontSize: t.font.size.small, flex: '0 0 50%' })
          }
        >
          <ul css={{ listStyle: 'disc' }}>
            {data?.description?.map((infoString) => <li key={infoString}>{infoString}</li>)}
          </ul>
        </div>
      </div>
    </section>
  );
};
