import { createContext, useContext, useEffect, useRef, useState } from 'react';

import { usePageContext } from '@stories/templates/Context/pageContext';
import { getSfApiClient } from '@utils/helpers';

import type { ProductType } from '@stories/organisms/HitListView/ReduceProps';
import type { TProductES } from '@vitafy/common-schemas';
import type { ReactNode } from 'react';

/**
 * A context to manage the state of a product with options
 *
 * productOptions are the different options a product can have.
 * For example a configurable can have different flavors. A bundle
 * can have an option for each item in the bundle.
 *
 * productConfiguration is the current configuration of the product
 * (i.e. the selected options). It must be defined before adding to the cart.
 */

export type TProductOption = Omit<NonNullable<TProductES['product_options']>[number], 'selection'> & {
  selection: Array<
    NonNullable<TProductES['product_options']>[number]['selection'][number] & {
      selected?: boolean;
    }
  >;
};
export type TProductOptions = TProductOption[];

/**
 * Object that represents selected options for a product.
 */
export type TProductConfiguration = {
  /**
   * Options that are mutually exclusive and must have a selected value.
   * Example: tastes, sizes, etc.
   */
  select: {
    [id: number]: number;
  };

  /**
   * Optional add on. E.g. products that can be added to a bundle optionally.
   * Example: shaker, upsells, etc.
   */
  checkbox: {
    [id: number]: number;
  };
};

export type TProductWithOptionsContextUtils = {
  selectOption: (option: { productOptionId: number; selectedOptionId: number }) => void;
  isProductConfigured: boolean;
  productConfiguration: TProductConfiguration | null;
  productOptionsAvailable: boolean;
  refreshOptionsFromApi: () => Promise<void>;
};

export type TProductWithOptionsContextValues = {
  baseprice: string;
  name: string;
  price: string;
  productId: string;
  productImage: string;
  productOptions: TProductOptions;
  productType?: ProductType | null;
};

export type TProductWithOptionsContext = TProductWithOptionsContextValues & TProductWithOptionsContextUtils;

export const ProductWithOptionsContext = createContext<TProductWithOptionsContext | undefined>(undefined);

export const hasProductOptions = (options: TProductOptions): boolean =>
  options?.length > 0 && options.some((option) => option.selection.length > 1);

export const validateProductConfiguration = (options: TProductOptions): boolean =>
  options.every(
    ({ selection }) => selection.length === 1 || selection.findIndex(({ selected }) => selected === true) > -1
  );

export const getProductConfiguartion = (productOptions: TProductOptions): TProductConfiguration | null => {
  if (!validateProductConfiguration(productOptions)) {
    return null;
  }

  const select = productOptions
    .filter(({ type }) => type === 'select')
    .reduce(
      (acc, { id, selection }) => ({ ...acc, [id]: selection.find(({ selected }) => selected === true)?.id }),
      {}
    );

  const checkbox = productOptions
    .filter(({ type }) => type === 'checkbox')
    .filter(({ selection }) => selection.find(({ selected }) => selected === true))
    .reduce(
      (acc, { id, selection }) => ({ ...acc, [id]: selection.find(({ selected }) => selected === true)?.id }),
      {}
    );

  return {
    select,
    checkbox
  };
};

type GetUpdatedOptionsSignature = (params: {
  productOptions: TProductOptions;
  productOptionId: number;
  selectedOptionId: number;
}) => TProductOptions;
export const getUpdatedOptions: GetUpdatedOptionsSignature = ({
  productOptions,
  productOptionId,
  selectedOptionId
}) =>
  productOptions?.map((productOption) => {
    if (productOption.id === productOptionId) {
      return {
        ...productOption,
        selection: productOption?.selection?.map((selectOption) => {
          if (productOption.type === 'select') {
            return {
              ...selectOption,
              selected: selectOption.id === selectedOptionId
            };
          } else {
            return {
              ...selectOption,
              selected: selectOption.id === selectedOptionId ? !selectOption.selected : selectOption.selected
            };
          }
        })
      };
    }
    return productOption;
  });

export const selectFirstInStockForUncofigured = (productOptions: TProductOptions): TProductOptions => {
  const productOptionsWithSelectAttribute = productOptions?.map((productOption) => {
    if (productOption.type !== 'select') {
      return productOption;
    }

    const selectedIndex = productOption.selection.findIndex((selectOption) => selectOption.selected === true);
    if (selectedIndex > -1) {
      return productOption;
    }

    const firstInStockIndex = productOption.selection.findIndex(
      (selectOption) => selectOption.is_in_stock === true
    );

    return {
      ...productOption,
      selection: productOption.selection.map((selectOption, idx) => ({
        ...selectOption,
        selected: idx === firstInStockIndex ? true : false
      }))
    };
  });

  return productOptionsWithSelectAttribute;
};

// Return the list of products in the options. Some bundles have the same product in different options,
// but we want to show it only once, e.g. in the details
export const getUniqueOptions = (productOptions: TProductOptions): TProductOptions => {
  return productOptions.reduce((acc, option) => {
    const productId = option.selection[0].product_id;
    if (!acc.some((opt) => opt.selection[0].product_id === productId)) {
      acc.push(option);
    }
    return acc;
  }, [] as TProductOptions);
};

export const getAlphabeticallySortedOptions = (productOptions: TProductOptions): TProductOptions =>
  productOptions.map((productOption) => ({
    ...productOption,
    selection: productOption.selection
      .map((option) =>
        option.is_in_stock ? option : { ...option, title: `${option.title} - leider ausverkauft` }
      )
      .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
  }));

export const useProductWithOptionsContext = (): TProductWithOptionsContext => {
  const context = useContext(ProductWithOptionsContext);
  if (typeof context === 'undefined') {
    throw new Error('useProductWithOptionsContext must be used within a ProductWithOptionsContext');
  }

  return context;
};

export const fetchProductOptionsFromApi = async ({ productId, storeCode }): Promise<TProductOptions> => {
  const sfApiClient = await getSfApiClient();
  const productApiResponse = await sfApiClient.product.getProductFromRedis({
    params: {
      storeCodeOrId: storeCode,
      productId: productId
    }
  });

  if (productApiResponse.status !== 200) {
    throw new Error('Product not found');
  }

  return productApiResponse.body.product_options || [];
};

export const getSelectProductOptions = (productOptions: TProductOptions): TProductOptions =>
  productOptions.filter(({ type }) => type === 'select');

export const getCheckboxProductOptions = (productOptions: TProductOptions): TProductOptions =>
  productOptions.filter(({ type }) => type === 'checkbox');

export const getProductOptionsWithFallbackTypes = (productOptions: TProductOptions): TProductOptions =>
  productOptions.map((productOption) => {
    if (['checkbox', 'select'].includes(productOption.type)) {
      return productOption;
    }

    // TODO hacky way to determine if the option is a checkbox
    // In categories API doesn't return the productOption type
    if (productOption.selection.length === 1 && productOption.title?.startsWith('Option')) {
      return {
        ...productOption,
        type: 'checkbox'
      };
    }

    return {
      ...productOption,
      type: 'select'
    };
  });

export const getOptionsWithDefault = (productOptions: TProductOptions): TProductOptions =>
  selectFirstInStockForUncofigured(
    getAlphabeticallySortedOptions(getProductOptionsWithFallbackTypes(productOptions))
  );

type TProductWithOptionsContextProvider = Omit<TProductWithOptionsContextValues, 'productOptions'> & {
  productOptions?: TProductOptions | null;
} & { children: ReactNode };

export const ProductWithOptionsContextProvider: React.FC<TProductWithOptionsContextProvider> = ({
  children,
  productOptions: initialProductOptions,
  ...valuesRest
}) => {
  const firstRender = useRef(true);
  const { storeCode } = usePageContext();
  const [productOptions, setProductOptions] = useState<TProductOptions>(
    getOptionsWithDefault(initialProductOptions || [])
  );
  const isProductConfigured = validateProductConfiguration(productOptions);
  const productConfiguration = getProductConfiguartion(productOptions);
  const productOptionsAvailable = hasProductOptions(productOptions);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    setProductOptions(getOptionsWithDefault(initialProductOptions || []));
  }, [initialProductOptions]);

  const selectOption = ({ productOptionId, selectedOptionId }) =>
    setProductOptions(getUpdatedOptions({ productOptions, productOptionId, selectedOptionId }));

  const refreshOptionsFromApi = async () => {
    const options = await fetchProductOptionsFromApi({
      productId: valuesRest.productId,
      storeCode: storeCode
    });
    setProductOptions(getOptionsWithDefault(options));
  };

  return (
    <ProductWithOptionsContext.Provider
      value={{
        productOptions,
        ...valuesRest,
        selectOption,
        isProductConfigured,
        productConfiguration,
        productOptionsAvailable,
        refreshOptionsFromApi
      }}
    >
      {children}
    </ProductWithOptionsContext.Provider>
  );
};
