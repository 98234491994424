/** @jsxImportSource @emotion/react */
import NextLink from 'next/link';

import { USE_NEXT_LINKS } from '@utils/constants';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { ParsedUrlQueryInput } from 'querystring';

export type LinkType = {
  label: string;
  href: string;
  blank?: boolean;
  title?: string;
  options?: LinkOptionsType;
};

export type LinkOptionsType = {
  inverted: boolean;
};

const Link: React.FC<LinkType> = ({ label, href, blank, title, options: { inverted = false } = {} }) => {
  return (
    <a
      href={href}
      title={title}
      target={blank ? '_blank' : undefined}
      rel="noreferrer"
      css={(t) => ({
        animate: 'color 175ms ease-out',
        cursor: 'pointer',
        ':hover': inverted ? { textDecoration: 'underline' } : { color: t.color.hover.copy }
      })}
    >
      {label}
    </a>
  );
};

type LinkSwitched = {
  children: React.ReactNode;
  href: string;
  pathname: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attrs?: { [key: string]: string | any };
  blank?: boolean;
  cssProps?: Interpolation<Theme>;
  query?: ParsedUrlQueryInput;
  scroll?: boolean;
  forceAnchorLink?: boolean;
};

const LinkSwitched: React.FC<LinkSwitched> = ({
  children,
  href,
  pathname,
  blank,
  cssProps,
  query,
  scroll = true,
  attrs,
  forceAnchorLink = false
}) => {
  if (USE_NEXT_LINKS && !forceAnchorLink) {
    return (
      <NextLink
        as={href}
        css={cssProps}
        href={{
          pathname,
          query
        }}
        scroll={scroll}
        {...attrs}
      >
        {children}
      </NextLink>
    );
  } else {
    return (
      <a css={cssProps} href={href} target={blank ? '_blank' : undefined} {...attrs}>
        {children}
      </a>
    );
  }
};

export default Link;
export { LinkSwitched };
