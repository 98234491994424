/** @jsxImportSource @emotion/react */
import { keyframes } from '@emotion/react';
import { useContext, useEffect } from 'react';

import Button from '@stories/atoms/Button';
import { zIndexes } from '@stories/atoms/utils';
import { PageContext } from '@stories/templates/Context/pageContext';

import { CartIcon } from '../IconDockerHeader';

type NotificationProductProps = {
  shown: boolean;
  setShown: (shown: boolean) => void;
};

const slideIn = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const NotificationProduct: React.FC = () => {
  const { isNotificationProductShown, setNotificationProductShown } = useContext(PageContext);
  useEffect(() => {
    if (isNotificationProductShown) {
      setTimeout(() => setNotificationProductShown(false), 5000);
    }
  }, [isNotificationProductShown, setNotificationProductShown]);

  if (!isNotificationProductShown) {
    return null;
  }

  return (
    <div
      css={(t) => ({
        animation: `${slideIn} 250ms forwards ease-out`,
        border: `thin solid ${t.color.grey.g92}`,
        background: 'white',
        maxWidth: '500px',
        padding: '1rem',
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: zIndexes.productNotification,
        width: '100%'
      })}
    >
      <div css={{ position: 'relative' }}>
        <h2
          css={(t) => ({
            font: t.headline.h4.font,
            display: 'block',
            marginBottom: '1rem',
            padding: '0 2rem',
            textAlign: 'center'
          })}
        >
          Warenkorb
        </h2>
        <div css={() => ({ position: 'absolute', right: '.75rem', top: 0 })}>
          <CartIcon />
        </div>
      </div>
      <Button label="Zum Warenkorb" href="/checkout/cart" />
    </div>
  );
};

export default NotificationProduct;
export type { NotificationProductProps };
