/** @jsxImportSource @emotion/react */
import Image from 'next/image';
import { useContext, useMemo } from 'react';

import RatingStars from '@stories/atoms/RatingStars';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media, mediaDown } from '@stories/theming/settings';
import { ASSET_URL } from '@utils/constants';

import type { GetStoreDataQuery } from '@gql/hygraph/graphql';
import type { CssProps } from '@stories/@types/custom';
import type { ReactElement } from 'react';

/* TRUSTED SHOP TOP BAR */

export type AggregateRatingType = {
  count: number;
  rating: number;
};

export type TopBarProps = Pick<NonNullable<GetStoreDataQuery['store']>, 'topBar'> & CssProps;

const logoTrusted = (
  <Image
    key="logoTrusted"
    src={`${ASSET_URL}/asset/images/etrusted-logo.svg`}
    alt="Etrusted logo"
    width="24"
    height="24"
    unoptimized
  />
);

const Topbar: React.FC<TopBarProps> = ({ topBar, cssProps }) => {
  const { etrustedData } = useContext(PageContext);

  const componentsMap = useMemo(
    () => ({
      '{{trusted_stars}}': <RatingStars key="RatingStars" ratingAverage={5} />,
      '{{logo_trusted}}': logoTrusted,
      '{{trusted_average}}': etrustedData?.aggregateRating?.rating ? (
        <span key="trustedAverage">{etrustedData?.aggregateRating?.rating}</span>
      ) : (
        ''
      ),
      '{{trusted_total_reviews}}': etrustedData?.aggregateRatingTotal?.count ? (
        <span key="trusteTotal">{etrustedData?.aggregateRatingTotal?.count}</span>
      ) : (
        ''
      )
    }),
    [etrustedData]
  );

  const replacePlaceholder = (
    placeholder: string,
    Component: ReactElement | string,
    originalString: string
  ) => {
    const splitted = originalString.split(placeholder);
    return splitted.length > 1
      ? [splitted[0].replace(' ', '\u00A0'), Component, splitted[1].replace(' ', '\u00A0')]
      : [originalString.replace(' ', '\u00A0')];
  };

  const replacePlaceholders = (originalString: string) => {
    let finalArray: (string | ReactElement)[] = [originalString];

    Object.entries(componentsMap).forEach(([placeholder, replacement]) => {
      finalArray = finalArray.flatMap((string) =>
        typeof string === 'string' ? replacePlaceholder(placeholder, replacement, string) : string
      );
    });

    return finalArray;
  };

  const itemsCss = useMemo(
    () =>
      ({
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        ':not(div):hover': { textDecoration: 'underline' }
      }) as const,
    []
  );

  return (
    <div css={(t) => ({ ...t.header.topBar, fontSize: t.font.size.small, overflow: 'hidden', ...cssProps })}>
      <div
        css={(t) => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
          maxWidth: t.section.width.regular,
          padding: '.25rem 1rem',
          width: '100%',
          [media('desktop')]: {
            justifyContent: 'space-between'
          },
          [mediaDown('desktop')]: {
            '> *:not(:nth-child(1))': {
              display: 'none'
            }
          }
        })}
      >
        {topBar
          ?.map(({ label, ...rest }) => ({ label: replacePlaceholders(label), ...rest }))
          .map(({ href, label, blank }, i) => {
            const key = i;
            return href ? (
              <a
                key={key}
                href={href || ''}
                target={blank ? '_blank' : undefined}
                css={{ '> *': { display: 'inline-block' }, ...itemsCss }}
              >
                {label}
              </a>
            ) : (
              <div key={key} css={itemsCss}>
                {label}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Topbar;
