/** @jsxImportSource @emotion/react */
import dynamic from 'next/dynamic';
import { useCallback, useContext, useEffect, useState } from 'react';

import useWindowSize from '@common/hooks/useWindowSize';
import { zIndexes } from '@stories/atoms/utils';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media, mediaDown } from '@stories/theming/settings';
import { getSearchClient } from '@utils/helpers';

import Header from '../Header';
import Topbar, { type TopBarProps } from '../Topbar';

import type { TNavigationProps } from '../Header/Navigation';
import type { THit, TSearchSuggestions } from '@vitafy/search-data-api-contracts';

const DropDownElementLazy = dynamic(() => import('./DropDownElement'), {
  loading: () => <div></div>,
  ssr: false
});

interface ISearchDropDown {
  favouriteProducts?: THit[] | TSearchSuggestions['products']['suggestionsList'];
  favouriteRecipies?: TSearchSuggestions['posts'];
  categories?: TSearchSuggestions['categories'];
  navigation: TNavigationProps;
  topBar: TopBarProps;
}

const SearchDropDown: React.FC<ISearchDropDown> = ({
  categories = [],
  favouriteProducts = [],
  favouriteRecipies = [],
  navigation,
  topBar
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [favouriteProductsState, setFavouriteProductsState] = useState(favouriteProducts);
  const [favouriteRecipiesState, setFavouriteRecipiesState] = useState(favouriteRecipies);
  const [productsTotal, setProductsTotal] = useState<number | null>(null);
  const [categoriesState, setCategoriesState] = useState(categories);
  const [tab, setTab] = useState(1);
  const { centeredSearchBar, showOverlay } = useContext(PageContext);

  const onSearchInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm((event.target as HTMLInputElement).value);
  };

  const searchInputCallbacks = {
    onFocus: () => {
      setOpen(true);
      showOverlay({ callback: () => setOpen(false) });
    },
    onChange: onSearchInputChange
  };
  const { storeCode } = useContext(PageContext);

  // needed cause iOS doesn't update vh or 100% height when displaying keyboard
  const { height: windowHeight } = useWindowSize();

  const fetchSearchSuggestions = useCallback(async () => {
    if (searchTerm === '') {
      // set 'bestseller' suggestion
      setFavouriteProductsState(favouriteProducts);
      setFavouriteRecipiesState(favouriteRecipies);
      setCategoriesState(categories);
    } else {
      const searchClient = await getSearchClient();
      const response = await searchClient.searchSuggestions({
        query: {
          storeCode,
          terms: searchTerm
        }
      });
      if (response.status !== 200) {
        throw new Error(`Can't search products.`);
      }
      setFavouriteRecipiesState(response.body.posts);
      setFavouriteProductsState(response.body.products.suggestionsList);
      setProductsTotal(response.body.products.total);
      setCategoriesState(response.body.categories);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  useEffect(() => {
    let debounceTimer;
    try {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(fetchSearchSuggestions, 400);
    } catch (error) {
      console.log(error);
    }
    return () => {
      clearTimeout(debounceTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        ...(open && {
          [mediaDown('desktop')]: {
            height: `${windowHeight}px`,
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            zIndex: zIndexes.darkOverlay + 1
          }
        })
      }}
    >
      {/* Do not show if etrustedData is undefined */}
      {topBar && (
        <Topbar
          topBar={topBar.topBar}
          cssProps={
            open
              ? {
                  display: 'none',
                  [media('desktop')]: {
                    display: 'block',
                    position: 'relative',
                    zIndex: zIndexes.darkOverlay + 1
                  }
                }
              : undefined
          }
        />
      )}
      <Header
        centeredSearchBar={centeredSearchBar ?? false}
        searchInputCallbacks={searchInputCallbacks}
        searchDropdownOpen={open}
        navigation={navigation}
        cssProps={{
          zIndex: 10,
          ...(open && {
            zIndex: zIndexes.darkOverlay + 1,
            [mediaDown('desktop')]: {
              backgroundColor: 'white',
              position: 'relative',
              transform: 'none',
              zIndex: zIndexes.darkOverlay + 2
            }
          })
        }}
      />
      {open && (
        <DropDownElementLazy
          favouriteProductsState={favouriteProductsState}
          favouriteRecipesState={favouriteRecipiesState}
          categoriesState={categoriesState}
          open={open}
          productsTotal={productsTotal}
          searchTerm={searchTerm}
          tab={tab}
          setTab={setTab}
        />
      )}
    </div>
  );
};

export default SearchDropDown;
