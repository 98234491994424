/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { sendGAEvent } from '@common/utlis';
import { media } from '@stories/theming/settings';

import SearchIcon from './search.svg';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';

type SearchInputProps = {
  placeholder: string;
  cssProps?: Interpolation<Theme>;
  callbacks?: {
    [key: string]: React.EventHandler<React.SyntheticEvent>;
  };
  cinematicMode?: boolean;
};

const SearchInput: React.FC<SearchInputProps> = ({
  callbacks = {},
  cssProps,
  cinematicMode,
  placeholder = ''
}) => {
  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(undefined);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const searchQuery = urlParams.get('q');
    setSearchInputValue(searchQuery || undefined);
  }, []);

  return (
    <form
      css={(t) => ({
        position: 'relative',
        [media('desktop')]: {
          transition: '450ms'
        },
        ...(cssProps as object),
        ...t.header.searchInput,
        ...(cinematicMode && t.header.cinematic.searchInput)
      })}
      onSubmit={(e) =>
        sendGAEvent({
          category: 'Search',
          action: 'search form submitted',
          // TODO: check typing for this
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          additionalTrackingParameters: { additionalParameter1: (e?.target as any)?.elements?.q?.value }
        })
      }
      action="/search"
      method="GET"
      autoComplete="off"
    >
      <input
        type="search"
        onFocus={() => sendGAEvent({ category: 'Search', action: 'input focus' })}
        placeholder={placeholder}
        name="q"
        {...callbacks}
        onChange={(e) => {
          callbacks?.onChange?.(e);
          const value = e.target.value;
          setSearchInputValue(value);
          if (value === '') {
            sendGAEvent({ category: 'Search', action: 'input cleared' });
          }
        }}
        value={searchInputValue}
        css={(t) => ({
          backgroundColor: 'transparent',
          border: 'none',
          borderRadius: t.header.searchInput?.borderRadius,
          fontSize: t.font.size.copy,
          margin: 0,
          padding: '.75rem',
          paddingRight: '3.5rem',
          width: '100%',
          '::placeholder': {
            color: 'hsl(0 0% 25%)',
            ...(cinematicMode && t.header.searchInput['::placeholder']),
            fontStyle: 'italic'
          },
          ':placeholder-shown': {
            textOverflow: 'ellipsis'
          },
          ':focus': {
            outlineColor: t.color.outline,
            '~ button::before': {
              opacity: 1
            }
          }
        })}
      />
      <button
        onClick={(e) =>
          sendGAEvent({
            category: 'Search',
            action: 'search button click',
            // TODO: check typing for this
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            additionalTrackingParameters: { additionalParameter1: (e?.target as any)?.elements?.q?.value }
          })
        }
        type="submit"
        aria-label="search"
        css={(t) => ({
          ...t.button.primary.solid,
          alignItems: 'center',
          backgroundColor: 'hsl(0 0% 100% / 0%)',
          border: 'none',
          borderRadius: `0 ${t.border.borderRadius} ${t.border.borderRadius} 0`,
          cursor: 'pointer',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
          margin: 0,
          minWidth: '3rem',
          position: 'absolute',
          right: '0',
          top: '0',
          transition: 'background-color 200ms ease-in-out',
          svg: {
            color: t.color.primary,
            transition: 'color 200ms ease-in-out'
          },
          '::before': {
            backgroundColor: 'hsl(0 0% 70% / 1)',
            content: '""',
            height: '60%',
            width: '1px',
            left: 0,
            opacity: 0,
            position: 'absolute',
            transition: 'opacity 200ms ease-in-out'
          },
          ':hover': {
            backgroundColor: 'hsl(0 0% 0% / 5%)'
            // boxShadow: 'inset 0 4px hsl(0 0% 0% / 5%)'
          }
        })}
      >
        <SearchIcon
          css={() => ({
            display: 'block',
            height: '1.75rem',
            transform: 'translateY(1px)',
            width: '1.75rem'
          })}
        />
      </button>
    </form>
  );
};

export default SearchInput;
export type { SearchInputProps };
