// Singleton pattern to ensure only one instance of the resize listener is created
import { useEffect } from 'react';

type ResizeCallback = (event: UIEvent) => void;

let resizeListenerCallbacks: ResizeCallback[] = [];

const handleResize = (e: UIEvent) => {
  resizeListenerCallbacks.forEach((callback: ResizeCallback) => {
    callback(e);
  });
};

export const useResizeListener = (callback: ResizeCallback) => {
  useEffect(() => {
    // Add the callback to the list of callbacks
    resizeListenerCallbacks.push(callback);

    // If it's the first callback, add the window resize listener
    if (resizeListenerCallbacks.length === 1) {
      window.addEventListener('resize', handleResize);
    }

    // Cleanup: Remove the callback when the component unmounts
    return () => {
      resizeListenerCallbacks = resizeListenerCallbacks.filter((cb) => cb !== callback);

      // If it's the last callback, remove the window resize listener
      if (resizeListenerCallbacks.length === 0) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [callback]);
};
