/** @jsxImportSource @emotion/react */
import { type Interpolation, type Theme, keyframes, useTheme } from '@emotion/react';
import toInteger from 'lodash/toInteger';
import Image from 'next/image';
import { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { forwardRef } from 'react';

import useTranslate from '@common/hooks/useTranslate';
import { sendGAEvent } from '@common/utlis';
import Button from '@stories/atoms/Button';
import useAddToCart from '@stories/atoms/Button/useAddToCart';
import Select from '@stories/atoms/FormElements/Select/Select';
import IconClose from '@stories/atoms/Icons/IconClose.svg';
import { zIndexes } from '@stories/atoms/utils';
import { PageContext } from '@stories/templates/Context/pageContext';
import { media, mediaDown } from '@stories/theming/settings';
import { getProductConfiguartion, getUpdatedOptions } from '@stories/utlis/context/ProductWithOptionsContext';

import type { TProductWithOptionsContextValues } from '@stories/utlis/context/ProductWithOptionsContext';

const containerClass: Interpolation<Theme> = {
  margin: '0 -1rem',
  overflow: 'auto',
  padding: '0 1rem 1.5rem'
};
const ctaClass: Interpolation<Theme> = {
  borderTop: 'solid thin var(--color-border-grey)',
  boxShadow: '0 0 10px -3px hsl(0 0% 0% / 20%)',
  margin: '0 -1rem',
  padding: '1rem',
  [media('desktop')]: {
    borderTop: 0,
    boxShadow: 'none'
  }
} as const;

const configOverlayStyle: Interpolation<Theme> = {
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  bottom: 0,
  maxHeight: '90vh',
  width: '100vw',
  borderTopLeftRadius: '16px',
  borderTopRightRadius: '16px',
  justifyContent: 'center',
  padding: '4rem 1rem',
  [mediaDown('desktop')]: {
    backgroundColor: 'white',
    borderRadius: '14px 14px 0 0',
    padding: '1.5rem var(--offset) 0',
    width: '100vw',
    zIndex: zIndexes.darkOverlay + 1
  },
  [media('desktop')]: {
    margin: 'auto',
    backgroundColor: 'white',
    borderRadius: '14px',
    padding: '1.5rem 1.5rem 1rem',
    maxHeight: '60vh',
    maxWidth: '460px',
    zIndex: zIndexes.darkOverlay + 1
  }
} as const;

const translations = {
  'Add to cart': {
    de_DE: 'In den Warenkorb'
  }
};

const dummyProductData: TProductWithOptionsContextValues = {
  baseprice: '10€ / KG',
  name: 'Produktname',
  price: '10€',
  productId: '11111',
  productImage: 'https://via.placeholder.com/60',
  productOptions: [],
  productType: 'configurable'
};

export type ProductConfigurationOverlayRef = {
  showOverlay: (productData: TProductWithOptionsContextValues) => void;
  hideOverlay: () => void;
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate(-50%, -35%);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  } 
`;

// eslint-disable-next-line @typescript-eslint/ban-types
export const ProductConfigurationOverlay = forwardRef<ProductConfigurationOverlayRef, {}>((_, ref) => {
  const t = useTranslate(translations);
  const theme = useTheme();
  const { showOverlay } = useContext(PageContext);
  const [productData, setProductData] = useState<TProductWithOptionsContextValues | null>(null);
  const [visible, setVisible] = useState(false);
  const { hideOverlay } = useContext(PageContext);
  const containerRef = useRef<HTMLDivElement>(null);

  const { isLoading, handleAddProductClick } = useAddToCart();

  useImperativeHandle(ref, () => ({
    showOverlay: (productData: TProductWithOptionsContextValues) => {
      setProductData(productData);
      setVisible(true);
      showOverlay({
        callback: () => {
          setVisible(false);
        }
      });
    },
    hideOverlay: () => setVisible(false)
  }));

  useEffect(() => {
    const currentRef = containerRef.current;

    const handleScroll = () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }

      // sendGAEvent({
      //   category: 'Product configuration overlay',
      //   action: 'scrolled',
      //   label: `{"productId": ${productId}}`
      // });
    };

    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef]);

  const { productId, productType, productOptions, productImage, name, price, baseprice } =
    productData || dummyProductData;

  const onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // TODO add to cart already tracked in useAddToCart
    sendGAEvent({ category: 'Product configuration overlay', action: 'add to cart', label: productId });

    handleAddProductClick({
      productId,
      productConfiguration: productOptions ? getProductConfiguartion(productOptions) : null,
      productType
    }).finally(() => {
      hideOverlay();
    });
  };

  const handleChange = (e) => {
    const selectedIndex = parseInt(e.target.selectedIndex);
    const selectedOptionId = parseInt(e?.target?.options[selectedIndex]?.value);
    const productOptionId = toInteger(e.target.getAttribute('select_element_id'));

    sendGAEvent({
      category: 'Product configuration overlay',
      action: 'select changed',
      label: `{"productId": ${productId}, "selectedId": ${selectedOptionId}, "elementId": ${productOptionId}}`
    });

    if (productData) {
      setProductData({
        ...productData,
        productOptions: getUpdatedOptions({ productOptions, productOptionId, selectedOptionId })
      });
    }
  };

  return (
    <form
      method="POST"
      id="vitafy-product-configuration-overlay"
      css={(t) => ({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        fontSize: t.font.size.small,
        justifyContent: 'center',
        position: 'fixed',
        left: 0,
        bottom: 0,
        opacity: 0,
        transform: 'translateY(120%)',
        transition: 'transform 0.2s ease-in, opacity 0.2s ease-in',
        zIndex: zIndexes.darkOverlay + 1,
        ...(visible && {
          opacity: 1,
          transform: 'translateY(0)',
          transition: 'transform 0.3s 0.3s ease-out, opacity 0s 0s'
        }),
        [media('desktop')]: {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, 100vh)',
          ...(visible && {
            animation: `${fadeIn} 0.4s ease-out forwards`
          })
        }
      })}
      onSubmit={onSubmit}
    >
      <div
        css={(t) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          flexDirection: 'column',
          fontFamily: t.font.family.copy
        })}
      >
        <div
          css={{
            ...configOverlayStyle,
            transform: 'translate(0%)'
          }}
        >
          <div
            css={(t) => ({
              fontSize: t.font.size.copy,
              marginBottom: '2rem',
              paddingRight: '2rem'
            })}
          >
            <h2 css={{ fontWeight: 'bold', fontSize: theme.font.size.large }}>Wähle einen Geschmack aus:</h2>
          </div>

          <div
            css={{
              display: 'grid',
              gap: '0 0.5rem',
              gridTemplate: `
                  "image text1" 
                  / 70px auto
                `,
              gridTemplateRows: 'min-content auto',
              fontSize: theme.font.size.footnote,
              lineHeight: '125%',
              marginBottom: '.5rem'
            }}
          >
            <Image
              src={productImage}
              alt={name ?? ''}
              width="60"
              height="60"
              css={{ gridArea: 'image', width: '60px', height: 'auto' }}
            />
            <div css={{ gridArea: 'text1', opacity: '0.8' }}>
              <div>{name}</div>
              {price}&nbsp;
              <span
                dangerouslySetInnerHTML={{ __html: `${baseprice}` || '' }}
                css={(t) => ({ fontSize: t.font.size.tiny, opacity: 0.75, fontWeight: 400 })}
              />
            </div>
          </div>

          <div css={containerClass} ref={containerRef}>
            <IconClose
              css={{ position: 'absolute', top: '14px', right: '13px' }}
              onClick={() => {
                hideOverlay();
              }}
            ></IconClose>
            {productOptions?.map((element) => (
              <div css={{ padding: '0.5rem 0' }} key={element.id}>
                <div css={{ display: 'flex', flexDirection: 'column' }}>
                  <Select
                    options={element?.selection.map((option) => ({
                      ...option,
                      disabled: !option.is_in_stock,
                      value: option.id.toString(),
                      label: option.title ?? ''
                    }))}
                    cssProps={{
                      select: {
                        width: '100%'
                      }
                    }}
                    attrs={{ select_element_id: element.id }}
                    onChange={handleChange}
                  ></Select>
                </div>
              </div>
            ))}
          </div>

          <div css={ctaClass}>
            <Button
              type="submit"
              label={`${t('Add to cart')}`}
              isLoading={isLoading}
              size={'big'}
              cssProps={{
                margin: 'auto',
                maxWidth: '420px',
                width: '100%'
              }}
            ></Button>
          </div>
        </div>
      </div>
    </form>
  );
});

ProductConfigurationOverlay.displayName = 'ProductConfigurationOverlay';
