import { Global, ThemeProvider } from '@emotion/react';
import { type CategoryListItemFromDBDto, type PageMetaDto } from '@vitafy/storefront-api-contracts-fetch';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module-defer';

import Overlay from '@stories/atoms/Overlay';
import { DialogueOverlayProvider } from '@stories/molecules/DialogueOverlay/dialogueOverlayContext';
import { NotificationContextProvider } from '@stories/molecules/Notification';
import NotificationProduct from '@stories/molecules/NotificationProduct';
import PromoBanner from '@stories/molecules/PromoBanner';
import MobileNavigation from '@stories/organisms/MobileNavigation';
import NewFooter from '@stories/organisms/NewFooter';
import NewsletterSubscriptionLP from '@stories/organisms/NewsletterSubscriptionLP';
import ProductConfigurationOverlay from '@stories/organisms/ProductConfigurationOverlay';
import SearchDropDown from '@stories/organisms/SearchDropDown';
import USPCardPack from '@stories/organisms/USPCardPack/USPCardPack';
import VoucherBar from '@stories/organisms/VoucherBar';
import { PageContext } from '@stories/templates/Context/pageContext';
import globalCss from '@stories/theming/global';
import { CustomPriceContextProvider } from '@stories/utlis/context/CustomPriceContext/CustomPriceContext';
import { ASSET_URL, FAVICONS } from '@utils/constants';
import { getFontsUrlsByStoreCode, getThemeByStoreCode } from '@utils/ui';

import type { GetNewsletterSubscriptionFormQuery, NextjsScriptLoadStrategy } from '@gql/hygraph/graphql';
import type { StoreCode } from '@stories/@types/custom';
import type { ProductConfigurationOverlayRef } from '@stories/organisms/ProductConfigurationOverlay/ProductConfigurationOverlay';
import type { TProductWithOptionsContextValues } from '@stories/utlis/context/ProductWithOptionsContext';
import type { StoreData } from '@utils/hygraph';
import type { THit } from '@vitafy/search-data-api-contracts';
import type { TTrustedShopsRatingResponse } from '@vitafy/storefront-api-contracts';

export type PageMeta = PageMetaDto & {
  metaImage?: string;
  type?: 'product';
  url?: string;
  rating?: {
    scale: number;
    count: number;
    value: number;
  };
  brand?: string;
  siteName?: string;
};

export type LayoutProps = React.PropsWithChildren<{
  pageMeta: PageMeta;
  storeCode: StoreCode;
  categoryTree: CategoryListItemFromDBDto[];
  defaultFavouriteProducts: THit[];
  storeId: number;
  newsletterSubcriptionForm?: NonNullable<
    NonNullable<GetNewsletterSubscriptionFormQuery['store']>['newsletterSubscriptionForm']
  >;
  gtmData: {
    isActive: boolean;
    containerId?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: Record<string, any>;
  };
  storeData: StoreData;
  isDraftMode: boolean;
  cinematicMode?: boolean;
  currentCategoryPath?: number[];
  etrustedData?: TTrustedShopsRatingResponse | null;
  pageType?: 'catalog_category_view_new' | 'icp';
  promoBannerCmsBlock?: string;
}>;

const LastSeenProductsLazy = dynamic(() => import('@stories/organisms/LastSeenProducts'), { ssr: false });
const FeedbackFormLazy = dynamic(() => import('@stories/organisms/FeedbackForm'), { ssr: false });

const useInitializeGTM = (gtmData, disableGTM) => {
  // const cart = useCart();

  const initializeGTM = () => {
    if (gtmData.isActive) {
      TagManager.initialize({ gtmId: gtmData.containerId, dataLayer: gtmData.dataLayer });
      // TagManager.dataLayer({
      //   dataLayerName: 'cartProducts',
      //   dataLayer: cart.products
      // });
    }
  };

  useEffect(() => {
    switch (disableGTM) {
      case undefined:
        initializeGTM();
        break;
      case 'defer':
        document.addEventListener('DOMContentLoaded', () => {
          initializeGTM();
        });
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtmData, disableGTM]);
};

const useOverlay = () => {
  const [isOverlayShown, setOverlayShown] = useState(false);
  const [closeOverlayOnClick, setCloseOverlayOnClick] = useState(true);
  const overlayClickCallbacks = useRef<(() => void)[]>([]);

  const showOverlay = ({
    callback,
    closeOnClick = true
  }: {
    callback?: () => void;
    closeOnClick?: boolean;
    disableBackButton?: boolean;
  }) => {
    setOverlayShown(true);
    setCloseOverlayOnClick(closeOnClick);
    if (callback !== undefined && typeof callback === 'function') {
      overlayClickCallbacks.current = [...overlayClickCallbacks.current, callback];
    }
  };

  const hideOverlay = () => {
    setOverlayShown(false);
  };

  useEffect(() => {
    if (!isOverlayShown) {
      overlayClickCallbacks.current.forEach((callback) => callback());
      overlayClickCallbacks.current = [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOverlayShown]);

  return { isOverlayShown, showOverlay, hideOverlay, closeOverlayOnClick };
};

export type ShowProductConfiguration = (productData: TProductWithOptionsContextValues) => void;

export default function Layout({
  categoryTree,
  children,
  currentCategoryPath,
  defaultFavouriteProducts,
  etrustedData,
  gtmData,
  isDraftMode,
  newsletterSubcriptionForm,
  pageMeta,
  pageType,
  storeCode,
  storeData,
  storeId,
  cinematicMode = false,
  promoBannerCmsBlock
}: LayoutProps) {
  const { productId } = useRouter().query;
  const [isNavigationShown, setNavigationShown] = useState(false);
  const [isNotificationProductShown, setNotificationProductShown] = useState(false);
  const [isFilterOverlayShown, setFilterOverlayShown] = useState(false);
  const overlayRef = useRef<ProductConfigurationOverlayRef>(null);
  const [overlayRefCurrent, setOverlayRefCurrent] = useState<ProductConfigurationOverlayRef | undefined>(
    undefined
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const [newFooterData, setNewFooterData] = useState<TNewFooter | undefined>(undefined);
  // const [errorMessageDisplayed] = useAtom(errorMessageDisplayedAtom);
  const router = useRouter();
  const { disableGTM, disableCM } = router.query;
  const { isOverlayShown, showOverlay, hideOverlay, closeOverlayOnClick } = useOverlay();

  const faviconHref = FAVICONS?.[storeCode] || '';

  useEffect(() => {
    router.events.on('routeChangeComplete', (url, { shallow }) => {
      if (!shallow) {
        hideOverlay();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (overlayRef.current) {
      setOverlayRefCurrent(overlayRef.current);
    }
  }, [overlayRef]);

  useInitializeGTM(gtmData, disableGTM);

  return (
    <ThemeProvider theme={getThemeByStoreCode(storeCode)}>
      <Head>
        {storeCode === 'de_de' && <link rel="stylesheet" href="https://use.typekit.net/lxk0vga.css"></link>}
        {getFontsUrlsByStoreCode(storeCode).map((fontUrl) => (
          <link key={fontUrl} rel="preload" href={fontUrl} as="font" />
        ))}
        {pageType && (
          <script
            id="vitafy-page-type"
            type="text/javascript"
            dangerouslySetInnerHTML={{ __html: `PAGE_TYPE="${pageType}";` }}
          />
        )}
        <title>{pageMeta.title}</title>
        <meta name="apple-mobile-web-app-title" content="Vitafy" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="title" content={pageMeta.metaTitle ?? ''} />
        <meta name="description" content={pageMeta.metaDescription ?? ''} />
        <meta name="robots" content={pageMeta.metaRobots ?? ''} />
        <meta name="keywords" content={pageMeta.metaKeywords ?? ''} />
        <meta name="image" content={pageMeta.metaImage ?? ''} />
        {pageMeta.type === 'product' && (
          <>
            <meta property="og:type" content="product" />
            <meta property="og:title" content={pageMeta.title} />
            {pageMeta.metaDescription && (
              <meta property="og:description" content={pageMeta.metaDescription} />
            )}
            <meta property="og:image" content={pageMeta.metaImage} />
            <meta property="og:url" content={pageMeta.url} />
          </>
        )}
        <link rel="icon" href={faviconHref} type="image/x-icon" />
      </Head>
      {storeData.headJs?.scripts?.map((script) => {
        if (script.scriptType === 'inline') {
          const { scriptId, javascript } = script as { scriptId: string; javascript: string };
          return (
            <Script id={scriptId} key={script.id}>
              {javascript}
            </Script>
          );
        }
        if (script.scriptType === 'remote') {
          const { src, strategy } = script as { src: string; strategy: NextjsScriptLoadStrategy };
          return <Script key={script.id} strategy={strategy} src={src} />;
        }

        return null;
      })}
      <PageContext.Provider
        value={{
          categoryTree,
          cinematicMode,
          currentCategoryPath,
          etrustedData,
          hideOverlay,
          isFilterOverlayShown,
          isNavigationShown,
          isNotificationProductShown,
          numberOfLinesShownInProductHit: storeData.numberOfLines ?? 4,
          setFilterOverlayShown,
          setNavigationShown,
          setNotificationProductShown,
          showOverlay,
          showProductConfiguration: overlayRefCurrent?.showOverlay,
          storeCode,
          storeId,
          centeredSearchBar: storeData?.centeredSearchBar || false,
          desktopNavigationNumberOfColumns: storeData?.desktopNavigationNumberOfColumns ?? 3,
          logo: storeData?.logo || undefined,
          formatPrice: () => 10,
          storeData: {
            activateWishlist: storeData?.activateWishlist || false,
            categoryHeroFallbackImage: storeData?.categoryHeroFallbackImage?.url || undefined,
            promoBannerCmsBlock
          }
        }}
      >
        <NotificationContextProvider>
          <CustomPriceContextProvider storeCode={storeCode} productId={Number(productId)}>
            {/* Preview mode badge */}
            {isDraftMode && (
              <div
                style={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  zIndex: 9999,
                  backgroundColor: 'red',
                  color: 'white',
                  padding: '.5rem'
                }}
              >
                Preview Mode
                <a
                  href={`/api/disable-draft?path=${encodeURIComponent(router.asPath)}`}
                  style={{
                    display: 'block',
                    color: 'white',
                    textDecoration: 'underline',
                    fontSize: '0.8rem',
                    textAlign: 'center'
                  }}
                >
                  Exit Preview
                </a>
              </div>
            )}
            <Global styles={globalCss} />
            <DialogueOverlayProvider>
              <VoucherBar />
              {promoBannerCmsBlock && cinematicMode && <PromoBanner innerHtml={promoBannerCmsBlock} />}
              <NotificationProduct />
              <MobileNavigation categoryTree={categoryTree} />
              <SearchDropDown
                favouriteProducts={defaultFavouriteProducts}
                navigation={{ categoryTree }}
                topBar={{ topBar: storeData?.topBar }}
              />
              {promoBannerCmsBlock && !cinematicMode && <PromoBanner innerHtml={promoBannerCmsBlock} />}
              <NotificationProduct />
              <Overlay
                visible={isOverlayShown}
                clickHandler={() => {
                  if (closeOverlayOnClick === true) {
                    hideOverlay();
                  }
                }}
              />
              <main>{children}</main>
              {storeData.uspCardPack && <USPCardPack {...storeData.uspCardPack}></USPCardPack>}
              <LastSeenProductsLazy />
              <FeedbackFormLazy />
              {newsletterSubcriptionForm && <NewsletterSubscriptionLP {...newsletterSubcriptionForm} />}
              {storeData?.newFooter && <NewFooter {...storeData?.newFooter} />}
              <ProductConfigurationOverlay ref={overlayRef} />
            </DialogueOverlayProvider>
          </CustomPriceContextProvider>
        </NotificationContextProvider>
      </PageContext.Provider>
      {!disableCM && (
        <Script
          id="consentmanager"
          src={`${ASSET_URL}/asset/scripts/consentManager.js`}
          strategy="lazyOnload"
        />
      )}
    </ThemeProvider>
  );
}
