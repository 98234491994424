import { OpenAPI as OpenAPIStorefrontAPI } from '@vitafy/storefront-api-contracts-fetch';

import { SEARCH_API_URL, STOREFRONT_API_URL } from './constants';

import type { TProductOptions } from '@stories/utlis/context/ProductWithOptionsContext';
import type { IncomingMessage, ServerResponse } from 'http';

/**
 * Returns ts-nest client for storefront api
 * @returns ts-nest client for search api
 */
export async function getSearchClient() {
  const tsCore = await import('@ts-rest/core');
  const searchContract = await import('@vitafy/search-data-api-contracts');
  return tsCore.initClient(searchContract.contract, {
    baseUrl: SEARCH_API_URL,
    baseHeaders: {},
    jsonQuery: true
  });
}

/**
 * Get ts-nest client for storefront APIs
 * @returns ts-nest client for storefront API
 */
export async function getSfApiClient() {
  const tsCore = await import('@ts-rest/core');
  const sfApiContracts = await import('@vitafy/storefront-api-contracts');
  return tsCore.initClient(sfApiContracts.contract, {
    baseUrl: STOREFRONT_API_URL,
    baseHeaders: {},
    jsonQuery: true
  });
}

export function setupRequests(): void {
  OpenAPIStorefrontAPI.BASE = STOREFRONT_API_URL;
}
export function setCacheHeaderForSSR(
  res: ServerResponse<IncomingMessage>,
  maxAge: number | undefined = undefined,
  staleTime: number | undefined = undefined
): void {
  res.setHeader(
    'Cache-Control',
    `public, s-maxage=${
      maxAge || process.env.SSR_CACHE_LIFETIME_IN_SECONDS || '60'
    }, stale-while-revalidate=${staleTime || process.env.SSR_STALE_WHILE_REVALIDATE_IN_SECONDS || '120'}`
  );
}
const isProductConfigured = (options: TProductOptions) =>
  options.every(
    ({ selection }) => selection.length === 1 || selection.findIndex(({ selected }) => selected === true) > -1
  );
export const getProductConfiguration = (productOptions: TProductOptions) => {
  if (!isProductConfigured(productOptions)) {
    return undefined;
  }

  return productOptions.reduce(
    (acc, { id, selection }) =>
      selection.length === 1
        ? { ...acc, [id]: selection[0].id }
        : { ...acc, [id]: selection.find(({ selected }) => selected === true)?.id },
    {}
  );
};
