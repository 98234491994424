/** @jsxImportSource @emotion/react */
import { mq } from '@stories/theming/settings';

type HeadlineSectionProps = {
  accent?: string;
  cssProps?: object;
  TagName?: string;
  title: string;
};

const HeadlineSection: React.FC<HeadlineSectionProps> = ({ accent, cssProps, TagName = 'h2', title }) => {
  const Tag = `${TagName}` as keyof JSX.IntrinsicElements;
  return (
    <Tag css={() => ({ ...cssProps, textAlign: 'center' })}>
      <span css={(t) => ({ ...t.headline.h3, display: 'block' })}>{title}</span>
      <span
        css={(t) =>
          mq({
            fontFamily: t.font.family.accent,
            fontWeight: 400,
            fontSize: ['55px', null, '72px', null, null],
            lineHeight: ['55px', null, '72px', null, null]
          })
        }
      >
        {accent}
      </span>
    </Tag>
  );
};

export default HeadlineSection;

export type { HeadlineSectionProps };
