/** @jsxImportSource @emotion/react */

import IconClose from '@stories/atoms/Icons/IconClose.svg';

import type { CssProps } from '@stories/@types/custom';

export type CloseIconButtonProps = {
  onClick: () => void;
  type?: 'default' | 'background';
} & CssProps;

export const CloseIconButton: React.FC<CloseIconButtonProps> = ({ onClick, cssProps, type = 'default' }) => {
  const iconSize = type === 'default' ? 24 : 18;

  return (
    <div
      css={{
        ...(type === 'background' && {
          background: 'hsl(0 0% 100% / 80%)',
          borderRadius: '20px',
          padding: '4px'
        }),
        ...cssProps
      }}
    >
      <IconClose
        css={{ display: 'block', width: `${iconSize}px`, height: `${iconSize}px` }}
        onClick={onClick}
      ></IconClose>
    </div>
  );
};
