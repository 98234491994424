/** @jsxImportSource @emotion/react */
import Color from 'color';

import Button from '@stories/atoms/Button';
import { container } from '@stories/atoms/Grid';
import Picture from '@stories/atoms/Picture';
import { media } from '@stories/theming/settings';

import type { GetNewsletterSubscriptionFormQuery } from '@gql/hygraph/graphql';

// const fadeIn = keyframes`
//   0% {
//     opacity: 0;
//     transform: matrix(1, -.025, -.1, 1, 0, 16)
//   }
//   100% {
//     opacity: 1;
//     transform: matrix(1, 0, 0, 1, 0, 0);
//   }
// `;

export type TNewsletterSubscriptionLP = NonNullable<
  NonNullable<GetNewsletterSubscriptionFormQuery['store']>['newsletterSubscriptionForm']
>;

const NewsletterSubscriptionLP: React.FC<TNewsletterSubscriptionLP> = ({
  headline,
  footnotes,
  subheading,
  backgroundColor,
  backgroundImage,
  overlayColor
}) => {
  // const { storeCode } = useContext(PageContext);

  return (
    <section
      css={(t) => ({
        backgroundColor: backgroundColor?.css ?? Color(t.color.primary).darken(0.1).string(),
        color: 'white',
        overflow: 'hidden',
        position: 'relative'
      })}
    >
      {/*{storeCode === 'vomachterhof' && (*/}
      {/*  <div*/}
      {/*    css={(t) => ({*/}
      {/*      aspectRatio: '1',*/}
      {/*      opacity: '0.15',*/}
      {/*      position: 'absolute',*/}
      {/*      right: '-700px',*/}
      {/*      top: '-700px',*/}
      {/*      width: '1000px',*/}
      {/*      [media('desktop')]: {*/}
      {/*        right: '30vw',*/}
      {/*        top: '-900px',*/}
      {/*        transform: 'translateX(100%)',*/}
      {/*        width: '1500px'*/}
      {/*      }*/}
      {/*    })}*/}
      {/*  >*/}
      {/*    <Image*/}
      {/*      alt="background decoration with a semi transparent flower"*/}
      {/*      fill*/}
      {/*      loader={({ src }) => src}*/}
      {/*      src={`${ASSET_URL}/asset/images/bgFlower.svg`}*/}
      {/*      unoptimized*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*)}*/}
      {backgroundImage && (
        <Picture {...backgroundImage} sizes={{ mobile: '100vw' }} cssProps={{ objectFit: 'cover' }} />
      )}
      {overlayColor?.css && (
        <div
          css={() => ({
            backgroundColor: overlayColor?.css,
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 0
          })}
        />
      )}
      <div
        css={(t) => ({
          ...container,
          maxWidth: t.section.width.content,
          textAlign: 'center',
          paddingTop: '5rem',
          paddingBottom: '6rem',
          position: 'relative',
          zIndex: 1,
          [media('desktop')]: {
            paddingTop: '7.5rem',
            paddingBottom: '8rem'
          }
        })}
      >
        <h2 className="fade" css={(t) => ({ ...t.headline.h2, color: 'white', marginBottom: '1.5rem' })}>
          {headline}
        </h2>
        <p className="fade" css={{ animationDelay: '800ms !important' }}>
          {subheading}
        </p>
        <form
          name="signup"
          id="signup-footer"
          action="/emarsys/newsletter/subscribepost"
          method="post"
          css={() => ({
            display: 'flex',
            flexWrap: 'wrap',
            gap: '.5rem',
            margin: '2rem auto',
            maxWidth: '400px',
            [media('desktop')]: {
              flexWrap: 'nowrap',
              maxWidth: 'none',
              width: '100%'
            }
          })}
        >
          <input type="hidden" id="double" name="double" value="double" />
          <label htmlFor="mail" css={{ visibility: 'hidden', position: 'absolute' }}>
            Email
          </label>
          <input
            id="mail"
            type="email"
            name="Email"
            placeholder="Deine Email-Adresse"
            required
            css={(t) => ({
              color: t.color.copy,
              width: '100%',
              padding: '11px 1rem 8px',
              backgroundColor: 'hsl(0 0% 100%)',
              backdropFilter: 'blur(15px)',
              border: 'thin solid hsla(0, 0%, 100%, .4)',
              minHeight: '3rem',
              borderRadius: '8px',
              font: 'inherit',
              '::placeholder': {
                font: 'inherit'
              },
              ':focus-visible': {
                outline: `thin solid ${t.color.outline}`
              }
            })}
          />
          <Button
            label="Anmelden"
            attrs={{ type: 'submit' }}
            cssProps={{
              width: '100%',
              minHeight: '3rem',
              [media('desktop')]: { width: 'auto', flex: '0 0 auto' }
            }}
          />
        </form>
        {footnotes?.html && (
          <div
            css={(t) => ({
              textAlign: 'left',
              fontSize: t.font.size.footnote,
              lineHeight: '120%',
              a: {
                textDecoration: 'underline'
              }
            })}
            dangerouslySetInnerHTML={{ __html: footnotes.html }}
          />
        )}
      </div>
    </section>
  );
};

export default NewsletterSubscriptionLP;
