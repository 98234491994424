/** @jsxImportSource @emotion/react */
import Image from 'next/image';

import { buildMediaUrl, buildSrcSet } from '@common/utlis';
import { breakpoints } from '@stories/theming/settings';

import type { Theme } from '@emotion/react';
import type { Interpolation } from '@emotion/serialize';
import type { Breakpoints } from '@gql/hygraph/graphql';
import type { viewport } from '@stories/theming/settings';

type sizes = {
  [key in viewport]?: string;
};

const srcsetWidths = [100, 200, 300, 500, 700, 900, 1200, 1400, 1800];

export type TPicture = {
  img: { url: string; alt: string };
  sources: Array<{ url: string; viewport: Breakpoints }>;
};

const Picture: React.FC<TPicture & { cssProps?: Interpolation<Theme>; sizes: sizes }> = ({
  img,
  sizes,
  sources,
  cssProps
}) => {
  const sizesString = Object.keys(breakpoints)
    .filter((viewport) => sizes[viewport])
    .reverse()
    .map((viewport) => {
      return viewport !== 'mobile'
        ? `(min-width: ${breakpoints[viewport]}px) ${sizes[viewport]}`
        : sizes[viewport];
    })
    .join(', ');

  // sources must be sorted by viewport from largest to smallest
  const sortedSources = sources.sort((a, b) => breakpoints[b.viewport] - breakpoints[a.viewport]);

  return (
    <picture>
      {sortedSources.map((source) => (
        <source
          key={source.viewport}
          srcSet={buildSrcSet(source.url, srcsetWidths)}
          media={source.viewport !== 'mobile' ? `(min-width: ${breakpoints[source.viewport]}px)` : undefined}
          sizes={sizesString}
        />
      ))}
      <Image alt={img.alt} src={buildMediaUrl(img.url)} unoptimized fill css={cssProps} />
    </picture>
  );
};

export default Picture;
