export const noScrollbarStyle = {
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none'
  }
} as const;

export const lineClamp = (lines) =>
  ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
    overflow: 'hidden'
  }) as const;

export const linkHover = (t) => ({
  color: t.color.hover.copy,
  textDecoration: 'underline'
});

export const visuallyHidden = {
  position: 'absolute',
  width: '1px',
  height: '1px',
  margin: '-1px',
  padding: 0,
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  border: 0
} as const;
