/** @jsxImportSource @emotion/react */

import Picture from '@stories/atoms/Picture';
import CallToActionLP from '@stories/molecules/CallToActionLP';
import { mq } from '@stories/theming/settings';

import { container } from '../Grid';
import HeadlineSection from '../Headline';

import type { HeadlineSectionProps } from '../Headline';
import type { ResponsivePictureFragment, SectionStyleFragment } from '@gql/hygraph/graphql';
import type { CssProps } from '@stories/@types/custom';
import type { CallToActionLPProps } from '@stories/molecules/CallToActionLP';

type SectionProps = React.PropsWithChildren<{
  headline?: HeadlineSectionProps;
  attrs?: { [key: string]: string };
  cta?: CallToActionLPProps;
  cssProps?: object;
  sectionWidth?: 'regular' | 'narrow' | 'content' | 'wide' | 'extraNarrow';
}>;

const Section: React.FC<SectionProps> = ({
  attrs,
  cssProps,
  children,
  cta,
  headline,
  sectionWidth = 'regular'
}) => {
  return (
    <section
      {...attrs}
      css={(t) =>
        mq({
          ...container,
          paddingTop: t.section.spacing.regular,
          paddingBottom: t.section.spacing.regular,
          maxWidth: t.section.width[sectionWidth],
          ...cssProps
        })
      }
    >
      {headline && <HeadlineSection {...headline} cssProps={{ marginBottom: '2.5rem' }} />}
      {children}
      {cta && (
        <div css={mq({ marginTop: ['2rem', null, '4rem'] })}>
          {' '}
          <CallToActionLP {...cta} />{' '}
        </div>
      )}
    </section>
  );
};

export default Section;

type SectionShortProps = React.PropsWithChildren<{
  headline?: string | null;
  cssProps?: object;
  sectionWidth?: 'regular' | 'narrow' | 'content' | 'wide' | 'extraNarrow';
}>;

const SectionShort: React.FC<SectionShortProps> = ({
  children,
  cssProps,
  headline,
  sectionWidth = 'regular'
}) => {
  return (
    <section
      css={(t) => ({
        margin: 'auto',
        maxWidth: t.section.width[sectionWidth],
        padding: '2.5rem var(--offset)',
        ...cssProps
      })}
    >
      {headline && (
        <h2
          css={(t) => ({
            ...t.headline.h3,
            margin: '1.5rem 0 2.5rem',
            textAlign: 'center'
          })}
        >
          {headline}
        </h2>
      )}
      {children}
    </section>
  );
};

export type SectionStyled = SectionStyleFragment &
  CssProps & {
    children?: React.ReactNode;
    headline?: string;
    spacingSize?: 'regular' | 'short';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    attrs?: { [key: string]: any };
  };
export const SectionStyled: React.FC<SectionStyled> = ({
  attrs,
  backgroundColor,
  backgroundImage,
  overlayColor,
  textColor,
  children,
  cssProps,
  headline,
  spacingSize = 'regular'
}) => {
  return (
    <section
      {...attrs}
      css={(t) =>
        mq({
          backgroundColor: backgroundColor?.css ?? 'white',
          color: textColor?.css ?? t.color.copy,
          paddingTop: t.section.spacing[spacingSize],
          paddingBottom: t.section.spacing[spacingSize],
          position: 'relative',
          ...(cssProps as object)
        })
      }
    >
      {backgroundImage && (
        <Picture
          {...(backgroundImage as ResponsivePictureFragment)}
          sizes={{ mobile: '100vw' }}
          cssProps={{ objectFit: 'cover' }}
        />
      )}
      {overlayColor?.css && (
        <div
          css={() => ({
            backgroundColor: overlayColor?.css,
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 0
          })}
        />
      )}
      {headline && (
        <h2
          css={(t) => ({
            ...t.headline.h3,
            margin: '1.5rem 0 2.5rem',
            textAlign: 'center',
            zIndex: 1
          })}
        >
          {headline}
        </h2>
      )}
      {children}
    </section>
  );
};

export { Section, SectionShort };
export type { SectionProps, SectionShortProps };
