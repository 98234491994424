import dynamic from 'next/dynamic';
import { useContext } from 'react';

import { noScrollbarStyle } from '@common/cssUtilities';
import { sendGAEvent } from '@common/utlis';
import CloseBigSvg from '@stories/atoms/Icons/close_big.svg';
import { zIndexes } from '@stories/atoms/utils';
import { PageContext } from '@stories/templates/Context/pageContext';
import { mq } from '@stories/theming/settings';

import type { TNavigationProps } from '@stories/organisms/Header/Navigation';

/** @jsxImportSource @emotion/react */

// const NavigationMultiLayerLazy = dynamic(
//   () => import('@stories/organisms/MobileNavigation/NavigationMultiLayer/NavigationMultiLayer')
// );

const NavigationSingleLayerLazy = dynamic(
  () => import('@stories/organisms/MobileNavigation/NavigationSingleLayer/NavigationSingleLayer')
);

const MobileNavigation: React.FC<TNavigationProps> = ({ categoryTree }) => {
  const { isNavigationShown: shown, setNavigationShown: setShown, hideOverlay } = useContext(PageContext);

  return (
    <div
      css={() => ({
        ...noScrollbarStyle,
        transition: 'transform 250ms ease-in-out',
        backgroundColor: 'white',
        boxShadow: '0 0 16px hsl(0, 0%, 0%, .2)',
        height: '100%',
        left: 0,
        maxHeight: '100%',
        maxWidth: '450px',
        position: 'fixed',
        top: 0,
        transform: 'translateX(-130%)',
        width: '90vw',
        zIndex: zIndexes.navigation.mobileMenu,
        ...(shown && { transform: 'translateX(0)' })
      })}
    >
      <CloseBigSvg
        data-testid="close-mobile-navigation"
        onClick={() => {
          setShown(false);
          hideOverlay();
          sendGAEvent({ category: 'Navigation', action: 'close mobile menu' });
        }}
        css={mq({
          color: 'white',
          position: 'absolute',
          right: ['-1.75rem', null, '-2.125rem'],
          top: '1rem',
          transform: 'scale(1.3)'
        })}
      />
      <div
        css={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative'
        }}
      >
        {shown && (
          <>
            {/*<NavigationMultiLayerLazy categoryTree={categoryTree} />*/}
            <NavigationSingleLayerLazy categoryTree={categoryTree} />
          </>
        )}
      </div>
    </div>
  );
};

export default MobileNavigation;
