import { media } from '@stories/theming/settings';

import { zIndexes } from '../utils';

const paddingHorizontal = '2rem';

const flyoutCssFn = (t) => ({
  backgroundColor: 'white',
  border: `1px solid ${t.color.grey.g92}`,
  borderRadius: '1rem',
  boxShadow: '1px 3px 16px hsl(0 0% 0% / .2)',
  maxWidth: `calc(${t.section.width.regular} - 2 * var(--offset))`,
  padding: '0 var(--offset)',
  width: '100%',
  zIndex: zIndexes.darkOverlay + 1,
  [media('desktop')]: {
    paddingLeft: `calc(${paddingHorizontal} + var(--offset))`,
    paddingRight: `calc(${paddingHorizontal} + var(--offset))`
  }
});

export { flyoutCssFn };
