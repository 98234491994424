import useSessionStorageState from 'use-session-storage-state';

const useWishlist = () => {
  const [vitafyWishlist, setVitafyWishlist] = useSessionStorageState<number[]>('vitafy_wishlist', {
    defaultValue: []
  });

  const addToWishList = (productId: number) => {
    setVitafyWishlist([...vitafyWishlist, productId]);
  };

  const removeFromWishlist = (productId: number) => {
    setVitafyWishlist(vitafyWishlist.filter((wishlistProductId) => wishlistProductId !== productId));
  };

  const toggleInWishlist = (productId: number) => {
    if (vitafyWishlist.some((wishlistProductId) => wishlistProductId === productId)) {
      removeFromWishlist(productId);
    } else {
      addToWishList(productId);
    }
  };

  return [vitafyWishlist, toggleInWishlist, addToWishList, removeFromWishlist] as const;
};

export default useWishlist;
