/** @jsxImportSource @emotion/react */
import { makeColumn, row } from '@stories/atoms/Grid';
import { SectionShort } from '@stories/atoms/Section/Section';
import USPCard from '@stories/molecules/USPCard';
import { media } from '@stories/theming/settings';

import type { GetStoreDataQuery } from '@gql/hygraph/graphql';

export type TUSPCardPack = NonNullable<NonNullable<GetStoreDataQuery['store']>['uspCardPack']> & {
  mobileBehavior?: 'default' | 'compact';
};

const USPCardPack: React.FC<TUSPCardPack> = ({ cards, mobileBehavior }) => {
  return (
    <SectionShort>
      <ul css={{ ...row, rowGap: '1rem' }}>
        {cards?.map((usp) => (
          <li
            key={usp.headline}
            css={{
              ...makeColumn(mobileBehavior === 'compact' ? 6 : 12),
              [media('tablet')]: makeColumn(6),
              [media('desktop')]: makeColumn(3)
            }}
          >
            <USPCard {...usp} cssProps={{ height: '100%' }} mobileBehavior={mobileBehavior} />
          </li>
        ))}
      </ul>
    </SectionShort>
  );
};

export default USPCardPack;
