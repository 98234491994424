/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

import CloseIconButton from '@stories/atoms/CloseIconButton';
import { mq } from '@stories/theming/settings';

export type PromoBannerProps = {
  innerHtml: string;
};

export const PromoBanner: React.FC<PromoBannerProps> = ({ innerHtml }) => {
  const theme = useTheme();
  const [shown, setShown] = useState<boolean>(false);

  useEffect(() => {
    const promoBannerShown = Cookies.get('promoBannerShown');
    if (promoBannerShown !== 'false') {
      setShown(true);
    }
  }, []);

  const handleCloseClick = () => {
    Cookies.set('promoBannerShown', 'false', { expires: 1 / 24 }); // 1 hours = 1/12 of a day
    setShown(false);
  };

  return (
    <div
      css={{
        backgroundColor: 'hsl(6.9deg 70.73% 48.24%)',
        border: 'solid thin white',
        position: 'relative',
        ...(!shown && { display: 'none' })
      }}
    >
      <div
        css={mq({
          color: 'white',
          fontSize: theme.font.size.footnote,
          fontWeight: 'regular',
          lineHeight: '125%',
          textAlign: 'center',
          '& a': {
            textDecoration: 'underline'
          },
          '& .promo': {
            padding: ['.5rem 3rem .5rem var(--offset)', null, '.5rem 4rem']
          }
        })}
        dangerouslySetInnerHTML={{ __html: innerHtml }}
      />
      <CloseIconButton
        type="background"
        onClick={handleCloseClick}
        cssProps={{ top: '50%', right: '.75rem', position: 'absolute', transform: 'translateY(-50%)' }}
      />
    </div>
  );
};
