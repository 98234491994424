/** @jsxImportSource @emotion/react */

import { useTheme } from '@emotion/react';
import { useEffect, useRef } from 'react';

import { zIndexes } from '@stories/atoms/utils';

export type NotificationType = 'success' | 'error' | 'info';
export type NotificationProps = {
  message: string;
  setShown: (shown: boolean) => void;
  shown: boolean;
  type?: NotificationType;
};

const infoIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M12 11V16M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21ZM12.0498 8V8.1L11.9502 8.1002V8H12.0498Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const successIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M15 10L11 14L9 12M12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
const errorIcon = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M12 9.00006V13.0001M4.37891 15.1999C3.46947 16.775 3.01489 17.5629 3.08281 18.2092C3.14206 18.7729 3.43792 19.2851 3.89648 19.6182C4.42204 20.0001 5.3309 20.0001 7.14853 20.0001H16.8515C18.6691 20.0001 19.5778 20.0001 20.1034 19.6182C20.5619 19.2851 20.8579 18.7729 20.9172 18.2092C20.9851 17.5629 20.5307 16.775 19.6212 15.1999L14.7715 6.79986C13.8621 5.22468 13.4071 4.43722 12.8135 4.17291C12.2957 3.94236 11.704 3.94236 11.1862 4.17291C10.5928 4.43711 10.1381 5.22458 9.22946 6.79845L4.37891 15.1999ZM12.0508 16.0001V16.1001L11.9502 16.1003V16.0001H12.0508Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;

const Notification: React.FC<NotificationProps> = ({ type = 'success', message, shown, setShown }) => {
  const theme = useTheme();
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const icon = {
    info: infoIcon,
    success: successIcon,
    error: errorIcon
  }[type];

  const backgroundColor = {
    info: theme.color.info,
    success: theme.color.success,
    error: theme.color.error
  }[type];

  useEffect(() => {
    if (shown) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      timeoutIdRef.current = setTimeout(() => {
        setShown(false);
      }, 5000);

      return () => {
        if (timeoutIdRef?.current !== null) {
          clearTimeout(timeoutIdRef.current);
        }
      };
    }
  }, [setShown, shown]);

  return (
    <div
      css={{
        backgroundClip: 'border-box',
        backgroundColor,
        border: '1px solid hsl(0 0% 0% / 17%)',
        borderRadius: '8px',
        boxShadow: `0px 0px 20px -8px rgba(0, 0, 0, 0.15), 0px 0px 10px -2px rgba(0, 0, 0, 0.35)`,
        color: 'white',
        display: 'flex',
        fontSize: theme.font.size.small,
        gap: '1rem',
        left: '50%',
        lineHeight: '130%',
        alignItems: 'center',
        opacity: shown ? 1 : 0,
        maxWidth: 'min(580px, calc(100vw - 2rem))',
        padding: '1.25rem 1.25rem 1.25rem .75rem',
        position: 'fixed',
        top: '1rem',
        transform: shown ? 'translate(-50%, 0)' : 'translate(-50%, -200%)',
        transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
        width: 'max-content',
        zIndex: zIndexes.notification
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: icon }} css={{ svg: { display: 'block' } }} />
      <p>{message}</p>
    </div>
  );
};

export default Notification;
