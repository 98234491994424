/** @jsxImportSource @emotion/react */
import { css, keyframes, useTheme } from '@emotion/react';
import { useRef, useState } from 'react';

import { useIntersection } from '@common/hooks/useIntersection';
import useTranslate from '@common/hooks/useTranslate';
import StarIcon from '@stories/atoms/Icons/starIcon.svg';
import StarRounded from '@stories/atoms/Icons/starRounded.svg';

type RatingStarsProps = {
  ratingAverage: number;
  cssProps?: object;
  totalReviews?: number;
};

const bumpIn = keyframes`
  from {
    opacity: 0;
    transform: scale(2.5);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const translations = {
  reviews: {
    de_DE: 'Bewertungen',
    nl_NL: 'Beoordelingen'
  }
};

const RatingStars: React.FC<RatingStarsProps> = ({ cssProps, ratingAverage, totalReviews }) => {
  return (
    <div css={{ display: 'inline-flex', alignItems: 'center', ...cssProps }}>
      <div css={css({ display: 'flex', marginRight: '.25rem' })}>
        {[...Array(Math.ceil(ratingAverage))].map((_, i) => (
          <StarIcon
            key={i}
            css={() => ({
              color: 'gold',
              height: '1rem',
              width: '1rem'
            })}
          />
        ))}
      </div>
      {totalReviews && (
        <span
          css={(theme) => ({
            color: theme.color.grey.g60,
            fontSize: theme.font.size.footnote,
            lineHeight: '100%'
          })}
        >
          ({totalReviews})
        </span>
      )}
    </div>
  );
};

type RatingStarsAnimatedProps = RatingStarsProps & {
  href?: string;
  starsWidth?: 1.25 | 1.5 | 1.75 | 2.25;
};
export const RatingStarsAnimated: React.FC<RatingStarsAnimatedProps> = ({
  cssProps,
  href,
  ratingAverage,
  starsWidth = 1.75,
  totalReviews
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);
  const translate = useTranslate(translations);
  const theme = useTheme();

  useIntersection(containerRef, () => setIsIntersecting(true), {
    name: 'noThreshold',
    rootMargin: '-50px',
    threshold: 0
  });

  const ContainerTagName = href ? 'a' : 'div';

  return (
    <ContainerTagName css={{ display: 'inline-flex', alignItems: 'center', ...cssProps }} href={href}>
      <div ref={containerRef} css={{ position: 'relative' }}>
        <div css={css({ display: 'flex', marginRight: '.5rem', gap: '1px' })}>
          {[...Array(5)].map((_, i) => (
            <StarRounded
              key={i}
              css={() => ({
                color: theme.color.grey.g92,
                height: `${starsWidth}rem`,
                width: 'auto'
              })}
            />
          ))}
        </div>
        <div
          css={css({
            display: 'flex',
            marginRight: '.5rem',
            gap: '1px',
            position: 'absolute',
            top: 0,
            left: 0
          })}
        >
          {[...Array(Math.ceil(ratingAverage))].map((_, i) => (
            <StarRounded
              key={i}
              preserveAspectRatio="xMinYMid slice"
              css={() => ({
                opacity: 0,
                ...(isIntersecting && {
                  animation: `${bumpIn} ${0.2 - 0.025 * i}s ${0.25 + i * 0.15 - i * 0.015}s ease-in both`
                }),
                color: 'gold',
                paddingRight:
                  ratingAverage !== 5 && i + 1 === Math.ceil(ratingAverage)
                    ? `${starsWidth * (Math.ceil(ratingAverage) - ratingAverage)}rem`
                    : 0,
                width: `${starsWidth}rem`,
                height: `${starsWidth}rem`
              })}
            />
          ))}
        </div>
      </div>
      <span css={{ fontWeight: 'bold', transform: 'translateY(0.05em)' }}>
        {Number(ratingAverage.toFixed(1))}/5
      </span>
      {totalReviews && (
        <span
          css={(theme) => ({
            color: theme.color.grey.g40,
            fontSize: theme.font.size.small,
            lineHeight: '100%',
            marginLeft: '.5rem',
            ...(href && { textDecoration: 'underline' })
          })}
        >
          ({totalReviews} <span>{translate('reviews')}</span>)
        </span>
      )}
    </ContainerTagName>
  );
};

export const RatingStarsCompact: React.FC<RatingStarsProps> = ({ cssProps, ratingAverage, totalReviews }) => {
  const theme = useTheme();
  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        fontSize: theme.font.size.small,
        gap: '.25rem',
        lineHeight: '0.9em',
        ...(cssProps as object)
      }}
    >
      <StarRounded
        css={() => ({
          color: 'gold',
          height: `1.25rem`,
          width: 'auto'
        })}
      />
      <div css={{ display: 'flex', fontSize: theme.font.size.large, fontWeight: 'bold' }}>
        {ratingAverage}
        <span css={{ color: theme.color.grey.g40, fontSize: theme.font.size.small }}>
          <span css={{ display: 'inline-block', margin: '0 1px' }}>/</span>5
        </span>
      </div>

      <span css={{ color: theme.color.grey.g40 }}>({totalReviews})</span>
    </div>
  );
};

export default RatingStars;

export type { RatingStarsProps };
